import { Component } from "react";
import BoxButton from "../../Layout/BoxButton";
import { withTranslation } from 'react-i18next';

class OrderBasics extends Component {
    render () {
        const {t, prices, formState, extrasChanged} = this.props

        return (
            <>
                <div className="form-section">
                    <div className="header-block">
                        {
                            /* Header for typical orders */
                            formState.orderType !== 'custom'
                            &&
                            <h2>{t("HOME.HEADERS.EXTRAS.TYPICAL")}</h2>
                        }
                        {
                            /* Header for custom orders */
                            formState.orderType === 'custom'
                            &&
                            <h2>{t("HOME.HEADERS.EXTRAS.CUSTOM")}</h2>
                        }
                        <h5>{t("HOME.SUB-HEADERS.CHECKBOX-GROUP")}</h5>
                    </div>
                    {/* Here the user chooses the add-on services that they want */}
                    <div id="extras" className="option-group">
                        {
                            /* Options for typical orders */
                            formState.orderType !== 'custom'
                            &&
                            <>
                                {
                                    formState.services.has('video')
                                    &&
                                    <BoxButton 
                                        buttonClicked = {extrasChanged}
                                        buttonType = "checkbox"
                                        buttonID = "drone-video"
                                        buttonName = "extras"
                                        buttonValue = "droneVideo"
                                        popoverValue =  {t("HOME.POPOVERS.EXTRAS.DRONE-VIDEO")}
                                        labelValue = {t("HOME.LABELS.EXTRAS.DRONE-VIDEO")}
                                        price = {`${prices.weddingPrices.extras.droneVideo}€`}
                                    >
                                    </BoxButton>
                                }

                                {
                                    formState.services.has('photography')
                                    &&
                                    <BoxButton 
                                        buttonClicked = {extrasChanged}
                                        buttonType = "checkbox"
                                        buttonID = "drone-photography"
                                        buttonName = "extras"
                                        buttonValue = "dronePhotography"
                                        popoverValue =  {t("HOME.POPOVERS.EXTRAS.DRONE-PHOTOGRAPHY")}
                                        labelValue = {t("HOME.LABELS.EXTRAS.DRONE-PHOTOGRAPHY")}
                                        price = {`${prices.weddingPrices.extras.dronePhotography}€`}
                                    >
                                    </BoxButton>
                                }
                            </>
                        }

                        {
                            /* Options for custom orders */
                            formState.orderType === 'custom'
                            &&
                            <>
                                <BoxButton 
                                    buttonClicked = {extrasChanged}
                                    buttonType = "checkbox"
                                    buttonID = "drone"
                                    buttonName = "extras"
                                    buttonValue = "drone"
                                    popoverValue =  {t("HOME.POPOVERS.EXTRAS.DRONE")}
                                    labelValue = {t("HOME.LABELS.EXTRAS.DRONE")}
                                    price = {`${prices.customPrices.extras.drone}€`}
                                >
                                </BoxButton>

                                <BoxButton 
                                    buttonClicked = {extrasChanged}
                                    buttonType = "checkbox"
                                    buttonID = "smoke-machine"
                                    buttonName = "extras"
                                    buttonValue = "smokeMachine"
                                    popoverValue =  {t("HOME.POPOVERS.EXTRAS.SMOKE-MACHINE")}
                                    labelValue = {t("HOME.LABELS.EXTRAS.SMOKE-MACHINE")}
                                    price = {`${prices.customPrices.extras.smokeMachine}€`}
                                >
                                </BoxButton>

                        
                                {/* <BoxButton 
                                    buttonClicked = {extrasChanged}
                                    buttonType = "checkbox"
                                    buttonID = "studio"
                                    buttonName = "extras"
                                    buttonValue = "studio"
                                    popoverValue =  {t("HOME.POPOVERS.EXTRAS.STUDIO")}
                                    labelValue = {t("HOME.LABELS.EXTRAS.STUDIO")}
                                    price = {`${prices.customPrices.extras.studio}€`}
                                >
                                </BoxButton> */}
                            </>
                        }
                    </div>
                </div>
                
            </>

        )
    }
}

export default withTranslation()(OrderBasics);