export const calculateRadioPrice = (oldOption, newOption, orderType, prices, serviceGroup, priceGroup) => {
    
    // Construct the name of the object that holds the prices for a specific order type.
    let typeGroup = orderType + 'Prices';

    // Initialize the old price that stems from the previously selected option.
    let oldPrice = 0;
    // Initialize the new price that belongs to the newly selected option.
    let newPrice = 0;
    
    // Check if there was a previously selected state.
    if(oldOption) {
        // Get the wedding price for the old option that was previously selected.
        oldPrice += prices[typeGroup][serviceGroup][priceGroup][oldOption];
    }
    // Get the wedding price for the new option that was selected now.
    newPrice += prices[typeGroup][serviceGroup][priceGroup][newOption];

    // Return the old and new prices.
    return {
        oldPrice,
        newPrice
    }
}

/**
 * Calculate the min & max price change by which the min-prices and max-prices will have to change by.
 * @param {*} selectedOption the option that the user just selected.
 * @param {*} orderType the order type was selected in the beginning.
 * @param {*} prices all of the prices.
 * @param {*} serviceGroup the price category that the option belongs to (video, photography, extras).
 * @param {*} priceGroup the price group that holds the price of the option that was selected.
 * @param {*} minMaxOptions the options that instead of holding a single price hold a min and max price.
 * @returns 
 */
export const calculateCheckboxPrice = (selectedOption, orderType, prices, serviceGroup, priceGroup = null) => {

    // Construct the name of the object that holds the prices for a specific order type.
    let typeGroup = orderType + 'Prices';
    
    // Initialize the prices by which to reduce or increase the minimum or maximum prices by.
    let minPriceChange = 0;
    let maxPriceChange = 0;

    // Initialize the flag which decides if the selected option has a price RANGE instead of a single price.
    let hasMinMaxPrices = false;

    // All options besides 'extras' belong to a price group.
    if(priceGroup) {
        // Check if the price object that corresponds to the selected option has min-max keys instead of a price number.
        if(isNaN(prices[typeGroup][serviceGroup][priceGroup][selectedOption])) {
            hasMinMaxPrices = true;
        }
        
        if(hasMinMaxPrices) {
            // Get the minimum price for the option that was checked or unchecked.
            minPriceChange += prices[typeGroup][serviceGroup][priceGroup][selectedOption]["min"];
            // Get the difference between the max and min price for the option that was checked or unchecked.
            maxPriceChange += (prices[typeGroup][serviceGroup][priceGroup][selectedOption]["max"] - prices[typeGroup][serviceGroup][priceGroup][selectedOption]["min"]);
        } else {
            // Get the price for the option that was checked or unchecked.
            minPriceChange += prices[typeGroup][serviceGroup][priceGroup][selectedOption];
        }
    } else {
        // Get the price for the option that was checked or unchecked.
        minPriceChange += prices[typeGroup][serviceGroup][selectedOption];
    }

    // Return the price.
    return {
        minPriceChange,
        maxPriceChange
    };
}