import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function LandingCarousel() {
    const { t } = useTranslation();

    function scrollToForm() {
        const formContent = document.querySelector('form');
        formContent.scrollIntoView(formContent);
    }

    return (
        <Carousel fade variant="dark">
            <Carousel.Item interval={6000}>
                <div id="first-slide" onClick={scrollToForm}>
                    <img
                        className="d-block carousel-photo"
                        src="/media/images/carousel/couple.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3 className="carousel-text">
                            {t("HOME.CAROUSEL.SLIDE-1")}
                        </h3>
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={4500}>
                <Link to="/samples">
                    <img
                        className="d-block carousel-photo"
                        src="/media/images/carousel/bride.jpg"
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h3 className="carousel-text">
                            {t("HOME.CAROUSEL.SLIDE-2")}
                        </h3>
                    </Carousel.Caption>
                </Link>
            </Carousel.Item>
            <Carousel.Item interval={4500}>
                <Link to="/team">
                    <img
                        className="d-block carousel-photo"
                        src="/media/images/carousel/team.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h3 className="carousel-text">
                            {t("HOME.CAROUSEL.SLIDE-3")}
                        </h3>
                    </Carousel.Caption>
                </Link>
            </Carousel.Item>
        </Carousel>
    );
}

export default LandingCarousel;