import './Footer.scss'; // Import the CSS file
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="container">

                <div className="list-container social-media">
                    <h3 className="footer-section-title">{t("FOOTER.HEADERS.FOLLOW")}</h3>
                    <ul className="footer-list">
                        <li><a href="https://instagram.com/video.photography_"><i className="fab fa-instagram"></i> Instagram</a></li>
                        <li><a href="https://facebook.com/www.video.photography"><i className="fab fa-facebook-f"></i> Facebook</a></li>
                        <li><a href="https://twitter.com/video_and_photo"><i className="fab fa-twitter"></i> Twitter</a></li>
                        <li><a href="https://linkedin.com/showcase/videophotography"><i className="fab fa-linkedin-in"></i> LinkedIn</a></li>
                    </ul>
                </div>

                {/* <div className="list-container development">
                    <h3 className="footer-section-title">About</h3>
                    <ul className="footer-list">
                        <li><a href="https://nikitas.io"><i className="fab fa-instagram"></i>Developer</a></li>
                    </ul>
                </div> */}

                <div className="list-container legal">
                    <h3 className="footer-section-title">{t("FOOTER.HEADERS.INFO")}</h3>
                    <ul className="footer-list">
                        {/* <li><Link to="contact">Contact us</Link></li> */}
                        <li><Link to="team">{t("FOOTER.LINKS.TEAM")}</Link></li>
                        <li><Link to="samples">{t("FOOTER.LINKS.SAMPLES")}</Link></li>
                        <li><Link to="contact">{t("FOOTER.LINKS.CONTACT")}</Link></li>
                        <li><Link to="privacy-policy">{t("FOOTER.LINKS.POLICY")}</Link></li>
                    </ul>
                </div>

            </div>
            <p className="copyright">{t("FOOTER.COPYRIGHT")}</p>
            <div id="trademark-container">
                <a href="https://inhinito.com"><img id="inhinito-trademark" src="media/images/icons/inhinito_logo.png" alt="Inhinito Trademark" /></a>
            </div>
            <p className="copyright">{t("FOOTER.GEMI")}</p>
      </footer>
    );
  }
  
  export default Footer;