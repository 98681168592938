import { Component, createRef } from "react";
import BoxButton from "./../../Layout/BoxButton";
import { withTranslation } from 'react-i18next';
import VisibilityDetector from "../../Util/VisibilityDetector";

class OrderBasics extends Component {

    state = {
        // The minimum price of order types.
        minWeddingPrice: null,
        minBaptismPrice: null,
        minWeddingWithBaptismPrice: null,
        minCustomPrice: null,

        minVideoPrice: null,
        minPhotographyPrice: null,
    }
    
    componentDidMount() {

        // Set the already selected order type.
        this.setMinimumServicePrices(this.props.formState.orderType);
  
        // Find the minimum price for a wedding.
        if(this.props.prices.weddingPrices.video.min < this.props.prices.weddingPrices.photography.min) {
            this.setState({
            minWeddingPrice: this.props.prices.weddingPrices.video.min
            });
        } else {
            this.setState({
            minWeddingPrice: this.props.prices.weddingPrices.photography.min
            });
        }

        // Find the minimum price for a baptism.
        if(this.props.prices.baptismPrices.video.min < this.props.prices.baptismPrices.photography.min) {
            this.setState({
            minBaptismPrice: this.props.prices.baptismPrices.video.min
            });
        } else {
            this.setState({
            minBaptismPrice: this.props.prices.baptismPrices.photography.min
            });
        }

        // Find the minimum price for a wedding with baptism.
        if(this.props.prices.weddingWithBaptismPrices.video.min < this.props.prices.weddingWithBaptismPrices.photography.min) {
            this.setState({
            minWeddingWithBaptismPrice: this.props.prices.weddingWithBaptismPrices.video.min
            });
        } else {
            this.setState({
            minWeddingWithBaptismPrice: this.props.prices.weddingWithBaptismPrices.photography.min
            });
        }

        // Find the minimum price for a custom order.
        if(this.props.prices.customPrices.video.min < this.props.prices.customPrices.photography.min) {
            this.setState({
            minCustomPrice: this.props.prices.customPrices.video.min
            });
        } else {
            this.setState({
            minCustomPrice: this.props.prices.customPrices.photography.min
            });
        }

    }

    componentDidUpdate(prevProps, prevState) {
    
        // Check if the user has changed order type.
        if(prevProps.formState.orderType !== this.props.formState.orderType) {
            // Set the new service prices.
            this.setMinimumServicePrices(this.props.formState.orderType);
        }
    }

    /**
     * Set the minimum services prices based on the selected order type.
     * @param {*} selectedOrderType 
     */
    setMinimumServicePrices (selectedOrderType){
        // Initialize the minimum video & photography prices.
        let minVideoPrice;
        let minPhotographyPrice;

        // Determine the minimum video & photography prices based on the selected order type.
        switch (selectedOrderType) {
            case 'wedding':
                minVideoPrice = this.props.prices.weddingPrices.video.min;  
                minPhotographyPrice = this.props.prices.weddingPrices.photography.min;
                break;
            case 'baptism':
                minVideoPrice = this.props.prices.baptismPrices.video.min;
                minPhotographyPrice = this.props.prices.baptismPrices.photography.min;
                break;
            case 'weddingWithBaptism':
                minVideoPrice = this.props.prices.weddingWithBaptismPrices.video.min;
                minPhotographyPrice = this.props.prices.weddingWithBaptismPrices.photography.min;
                break;
            default:
                minVideoPrice = this.props.prices.customPrices.video.min;
                minPhotographyPrice = this.props.prices.customPrices.photography.min;
        }

        // Set the minimum video & photography prices to the state.
        this.setState({
            minVideoPrice: minVideoPrice,
            minPhotographyPrice: minPhotographyPrice
        });
    }
    
    // Set the new visibility state for the orderType section.
    handleVisibilityChange = (isVisible) => {
        this.props.orderTypeVisibilityChanged(isVisible);
    };

    render () {
        const {t, formState, orderTypeChanged, servicesChanged} = this.props
        

        return (
            <div id="order-basics">
                
                <VisibilityDetector onVisibilityChange={this.handleVisibilityChange}>

                    <div className="form-section">
                        <div className="header-block">
                            <h2>{t("HOME.HEADERS.ORDER-TYPE")}</h2>
                            <h5>{t("HOME.SUB-HEADERS.RADIO-GROUP")}</h5>
                        </div>

                        {/* Here the user chooses the type of the order */}
                        <div id="order-type" className="option-group">
                            <BoxButton 
                                buttonClicked = {orderTypeChanged}
                                buttonType = "radio"
                                buttonID = "wedding"
                                buttonName = "order-type"
                                buttonValue = "wedding"
                                popoverValue =  {t("HOME.POPOVERS.WEDDING")}
                                labelValue = {t("HOME.LABELS.ORDER-TYPE.WEDDING")}
                                price = {`${t("COMMON.FROM")} ${this.state.minWeddingPrice}€`}
                            >
                            </BoxButton>

                            <BoxButton 
                                buttonClicked = {orderTypeChanged}
                                buttonType = "radio"
                                buttonID = "baptism"
                                buttonName = "order-type"
                                buttonValue = "baptism"
                                popoverValue =  {t("HOME.POPOVERS.BAPTISM")}
                                labelValue = {t("HOME.LABELS.ORDER-TYPE.BAPTISM")}
                                price = {`${t("COMMON.FROM")} ${this.state.minBaptismPrice}€`}
                            >
                            </BoxButton>

                            <BoxButton 
                                buttonClicked = {orderTypeChanged}
                                buttonType = "radio"
                                buttonID = "weddingWithBaptism"
                                buttonName = "order-type"
                                buttonValue = "weddingWithBaptism"
                                popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM")}
                                labelValue = {t("HOME.LABELS.ORDER-TYPE.WEDDING-BAPTISM")}
                                price = {`${t("COMMON.FROM")} ${this.state.minWeddingWithBaptismPrice}€`}
                            >
                            </BoxButton>

                            <BoxButton 
                                buttonClicked = {orderTypeChanged}
                                buttonType = "radio"
                                buttonID = "custom-order"
                                buttonName = "order-type"
                                buttonValue = "custom"
                                popoverValue =  {t("HOME.POPOVERS.CUSTOM")}
                                labelValue = {t("HOME.LABELS.ORDER-TYPE.CUSTOM")}
                                price = {`${t("COMMON.FROM")} ${this.state.minCustomPrice}€`}
                            >
                            </BoxButton>
                        </div>
                    </div>

                </VisibilityDetector>

                {
                    formState.orderType 
                    &&
                    <div id="services-section" className="form-section">
                        <div className="header-block">
                            <h2>{t("HOME.HEADERS.SERVICES")}</h2>
                            <h5>{t("HOME.SUB-HEADERS.SERVICES")}</h5>
                        </div>
                        {/* Here the user chooses the services that they want (video, photography or both) */}
                        <div id="services" className="option-group">
                            <BoxButton 
                                buttonClicked = {servicesChanged}
                                buttonType = "checkbox"
                                buttonID = "video"
                                buttonName = "services"
                                buttonValue = "video"
                                popoverValue = {
                                    (this.props.formState.orderType === 'wedding') ? t("HOME.POPOVERS.VIDEO.WEDDING") :
                                    (this.props.formState.orderType === 'baptism') ? t("HOME.POPOVERS.VIDEO.BAPTISM") :
                                    (this.props.formState.orderType === 'weddingWithBaptism') ? t("HOME.POPOVERS.VIDEO.WEDDING-BAPTISM") :
                                    t("HOME.POPOVERS.VIDEO.CUSTOM")
                                }
                                labelValue = {
                                    (this.props.formState.orderType === 'wedding') ? t("HOME.LABELS.SERVICES.WEDDING.VIDEO") :
                                    (this.props.formState.orderType === 'baptism') ? t("HOME.LABELS.SERVICES.BAPTISM.VIDEO") :
                                    t("HOME.LABELS.SERVICES.CUSTOM.VIDEO")
                                }
                                price = {`${t("COMMON.STARTS-AT")} ${this.state.minVideoPrice}€`}
                            >
                            </BoxButton>

                            <BoxButton 
                                buttonClicked = {servicesChanged}
                                buttonType = "checkbox"
                                buttonID = "photography"
                                buttonName = "services"
                                buttonValue = "photography"
                                popoverValue = {
                                    (this.props.formState.orderType === 'wedding') ? t("HOME.POPOVERS.PHOTOGRAPHY.WEDDING") :
                                    (this.props.formState.orderType === 'baptism') ? t("HOME.POPOVERS.PHOTOGRAPHY.BAPTISM") :
                                    (this.props.formState.orderType === 'weddingWithBaptism') ? t("HOME.POPOVERS.PHOTOGRAPHY.WEDDING-BAPTISM") :
                                    t("HOME.POPOVERS.PHOTOGRAPHY.CUSTOM")
                                }
                                labelValue = {
                                    (this.props.formState.orderType === 'wedding') ? t("HOME.LABELS.SERVICES.WEDDING.PHOTOGRAPHY") :
                                    (this.props.formState.orderType === 'baptism') ? t("HOME.LABELS.SERVICES.BAPTISM.PHOTOGRAPHY") :
                                    t("HOME.LABELS.SERVICES.CUSTOM.PHOTOGRAPHY")
                                }
                                price = {`${t("COMMON.STARTS-AT")} ${this.state.minPhotographyPrice}€`}
                            >
                            </BoxButton>

                            {
                                (formState.orderType !== 'custom')
                                &&
                                formState.services.has('video')
                                &&
                                formState.services.has('photography')
                                &&
                                <span className="directions">
                                    <strong>{t("HOME.LABELS.SERVICES.NOTICE")}</strong> 👇
                                </span>
                            }


                        </div>
                    </div>
                }

            </div>
        )
    }
}

// export default OrderBasics;
export default withTranslation()(OrderBasics);