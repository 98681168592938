import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTranslation } from "react-i18next";
import {useState, useRef} from "react";

import Popover from 'react-bootstrap/Popover';
import Modal from 'react-bootstrap/Modal';
import CriticalTip from './CriticalTip';

const MyPopover = (props) => {

  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const targetElementRef = useRef(null);



  const handleActiveStyles = (event) => {
    // console.log('The popover props: ', props);
    let pushableButtons = document.getElementsByClassName('pushable');
  
    if(event.type === "touchstart" || event.type === "mousedown" || event.type === "mouseenter") {
      
      for (let i = 0; i < pushableButtons.length; i++) {
        const button = pushableButtons[i];
        // Remove the active styles for the pushable button while the info button is pushed.
        button.classList.remove("pushable-active");
      }
      
    } else {

      if(event.type === "mouseleave") {
        for (let i = 0; i < pushableButtons.length; i++) {
          const button = pushableButtons[i];  
          // Add the active styles for the pushable button after the info button is released.
          button.classList.add("pushable-active");
        }
      }
      
    }
  }


  
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        {t("HOME.POPOVERS.EXPLANATION")}
        <CloseButton className="close-button" onClick={() => document.body.click()}/>
      </Popover.Header>
      <Popover.Body>
        {props.popoverValue}

        {
          // Check if the popover features an image.
          (props.popoverImage)
          &&
          <>
            <div onClick={() => setShow(true)}>
              <img src="/media/images/album/overlay.png" className="popover-overlay" alt="Album zoom button"/>
              <img 
                src={props.popoverImage} 
                alt="Album Sample" 
                className="popover-image"
              />
            </div>
          </>
        }
      </Popover.Body>
    </Popover>
  );


  return (
    <>
      <OverlayTrigger 
        trigger="click"
        placement="top"
        overlay={popover}
        rootClose
      >
        <Button
          ref={targetElementRef}
          variant="info" 
          className="info-button pulse" 
          onTouchStart={handleActiveStyles} 
          onMouseUp={handleActiveStyles} 
          onMouseDown={handleActiveStyles} 
          onMouseEnter={handleActiveStyles} 
          onMouseLeave={handleActiveStyles}
        >
          <span className="info-button-icon">?</span>
        </Button>
      </OverlayTrigger>

      {
        (props.buttonID === 'wedding')
        &&
        <CriticalTip target={targetElementRef.current} content={t("HOME.POPOVERS.EXPLANATION")} />
      }

      {
        // Check if the popover features an image.
        (props.popoverImage)
        &&
        <>
          <Modal
            show={show}
            size="xl"
            onHide={() => setShow(false)}
            dialogClassName="modal-150w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                {props.imageHeader}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <img src={props.popoverImage} className="modal-content" alt="gallery pic"/>
            
            </Modal.Body>
          </Modal>
        </>
      }
    </>
    
  )
  
  
}

export default MyPopover