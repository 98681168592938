const measurementId = 'G-Z3EB20K03T';
const gtmContainerId = 'GTM-TFDRNGW';


const loadGoogleAnalytics = () => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.async = true;
    document.head.appendChild(script);
  
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
    
        // Initialize the G4 tag & Google tag manager.
        initializeAnalytics();
    };
};

const loadGoogleTagManager = () => {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmContainerId);
};
  
export const initializeAnalytics = () => {
    // Check if the gtag is present in the browser window.
    if (window.gtag) {
        // Load the G4 tag.
        window.gtag('config', measurementId, { anonymize_ip: true });
    }
    // Check if the tag manager data layer is present in the browser window.
    if (!window.dataLayer) {
        // Load the rag manager container.
        loadGoogleTagManager();
    }
};
  
export const trackPageView = () => {
    if (localStorage.getItem('cookieConsent') === 'true') {
      if (!window.gtag) {
        loadGoogleAnalytics();
      } else {
        window.gtag('event', 'page_view', {
          page_location: window.location.href,
          page_path: window.location.pathname,
          send_to: measurementId,
        });
      }
    }
};
  
export const trackEvent = (eventName, eventParams) => {
    if (localStorage.getItem('cookieConsent') === 'true') {
        if (!window.gtag) {
            loadGoogleAnalytics();
        } else {
            window.gtag('event', eventName, eventParams);
        }
    }
};