import { Component } from "react";
import BoxButton from "../../Layout/BoxButton";
import { withTranslation } from 'react-i18next';
import DirectionsNotice from "../../Layout/DirectionsNotice";

class TypicalOrder extends Component {
    
    render () {
        const {t, prices, formState, videoCoverageChanged, videoPackageChanged, photographyEventSizeChanged, photographyAlbumChanged, photographyPackageChanged} = this.props;

        return  (
            <div id="typical-order">

                {
                    formState.services.has('video')
                    &&
                    <div id="video-order" className="typical-group">

                        <h1 className="typical-order-header">{t("HOME.HEADERS.VIDEO-OPTIONS.MAIN-HEADER")}</h1>

                        <div className="form-section">
                            <div className="header-block">
                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'wedding'
                                    &&
                                    <h2>{t("HOME.HEADERS.VIDEO-OPTIONS.WEDDING.PACKAGE")}</h2>
                                }
                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'baptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.VIDEO-OPTIONS.BAPTISM.PACKAGE")}</h2>
                                }
                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.VIDEO-OPTIONS.WEDDING-BAPTISM.PACKAGE")}</h2>
                                }
                                <h5>{t("HOME.SUB-HEADERS.CHECKBOX-GROUP")}</h5>
                            </div>
                            {/* Here the user chooses the package options to better determine the price. */}
                            <div id="video-package" className="option-group">
                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'wedding'
                                    &&
                                    <>
                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-preparations"
                                            buttonName = "video-package"
                                            buttonValue = "preparations"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-VIDEO.PACKAGE.PREPARATIONS")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PREPARATIONS")}
                                            price = {`${prices.weddingPrices.video.package.preparations}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-party"
                                            buttonName = "video-package"
                                            buttonValue = "party"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-VIDEO.PACKAGE.PARTY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PARTY")}
                                            price = {`${prices.weddingPrices.video.package.party}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-next-day"
                                            buttonName = "video-package"
                                            buttonValue = "nextDay"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-VIDEO.PACKAGE.NEXT-DAY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.NEXT-DAY")}
                                            price = {`${prices.weddingPrices.video.package.nextDay}€`}
                                        >
                                        </BoxButton>
                                    </>
                                }

                                {
                                    /* Include the scenes of the family at their home with the baby */
                                    formState.orderType === 'baptism'
                                    &&
                                    <>
                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-family-moments"
                                            buttonName = "video-package"
                                            buttonValue = "family"
                                            popoverValue =  {t("HOME.POPOVERS.BAPTISM-VIDEO.PACKAGE.FAMILY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.VIDEO-FAMILY")}
                                            price = {`${prices.baptismPrices.video.package.family}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-party"
                                            buttonName = "video-package"
                                            buttonValue = "party"
                                            popoverValue =  {t("HOME.POPOVERS.BAPTISM-VIDEO.PACKAGE.PARTY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PARTY")}
                                            price = {`${prices.baptismPrices.video.package.party}€`}
                                        >
                                        </BoxButton>
                                    </>
                                }

                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <>
                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-preparations"
                                            buttonName = "video-package"
                                            buttonValue = "preparations"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-VIDEO.PACKAGE.PREPARATIONS")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PREPARATIONS")}
                                            price = {`${prices.weddingWithBaptismPrices.video.package.preparations}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-party"
                                            buttonName = "video-package"
                                            buttonValue = "party"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM-VIDEO.PACKAGE.PARTY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PARTY")}
                                            price = {`${prices.weddingWithBaptismPrices.video.package.party}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {videoPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "video-next-day"
                                            buttonName = "video-package"
                                            buttonValue = "nextDay"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM-VIDEO.PACKAGE.NEXT-DAY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.NEXT-DAY")}
                                            price = {`${prices.weddingWithBaptismPrices.video.package.nextDay}€`}
                                        >
                                        </BoxButton>
                                    </>
                                }
                            </div>
                        </div>


                        <div className="form-section">
                            <div className="header-block">
                                {
                                    formState.orderType === 'wedding'
                                    &&
                                    <h2>{t("HOME.HEADERS.VIDEO-OPTIONS.WEDDING.COVERAGE")}</h2>
                                }
                                {
                                    formState.orderType === 'baptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.VIDEO-OPTIONS.BAPTISM.COVERAGE")}</h2>
                                }
                                {
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.VIDEO-OPTIONS.WEDDING-BAPTISM.COVERAGE")}</h2>
                                }
                                <h5>{t("HOME.SUB-HEADERS.RADIO-GROUP")}</h5>
                            </div>

                            {/* Here the user choose if they want detailed coverage of their event or not */}
                            <div id="video-coverage" className="option-group">
                                {/* A video with only the highlights of the event */}
                                <BoxButton 
                                    buttonClicked = {videoCoverageChanged}
                                    buttonType = "radio"
                                    buttonID = "video-highlights"
                                    buttonName = "video-coverage"
                                    buttonValue = "highlights"
                                    popoverValue = { 
                                        (this.props.formState.orderType === 'wedding') ?
                                        t("HOME.POPOVERS.WEDDING-VIDEO.COVERAGE.HIGHLIGHTS") :
                                        (
                                            (this.props.formState.orderType === 'baptism') ?
                                            t("HOME.POPOVERS.BAPTISM-VIDEO.COVERAGE.HIGHLIGHTS") 
                                            :
                                            t("HOME.POPOVERS.WEDDING-BAPTISM-VIDEO.COVERAGE.HIGHLIGHTS")
                                        )
                                    }
                                    labelValue = {t("HOME.LABELS.COVERAGE.HIGHLIGHTS")}
                                    price = {`${prices.baptismPrices.video.coverage.highlights}€`}
                                >
                                </BoxButton>

                                {/* A detailed video of the entire event */}
                                <BoxButton
                                    buttonClicked = {videoCoverageChanged}
                                    buttonType = "radio"
                                    buttonID = "video-detailed"
                                    buttonName = "video-coverage"
                                    buttonValue = "detailed"
                                    popoverValue = { 
                                        (this.props.formState.orderType === 'wedding') ?
                                        t("HOME.POPOVERS.WEDDING-VIDEO.COVERAGE.DETAILED") :
                                        (
                                            (this.props.formState.orderType === 'baptism') ?
                                            t("HOME.POPOVERS.BAPTISM-VIDEO.COVERAGE.DETAILED") 
                                            :
                                            t("HOME.POPOVERS.WEDDING-BAPTISM-VIDEO.COVERAGE.DETAILED")
                                        )
                                    }
                                    labelValue = {t("HOME.LABELS.COVERAGE.DETAILED")}
                                    price = {`${prices.baptismPrices.video.coverage.detailed}€`}
                                >
                                </BoxButton>

                                <DirectionsNotice />
                            </div>
                        </div>
                        
                    </div>
                }

                {
                    formState.services.has('photography')
                    &&
                    <div id="photography-order" className="typical-group">

                        <h1 className="typical-order-header">{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.MAIN-HEADER")}</h1>

                        <div className="form-section">
                            <div className="header-block">
                                {
                                    formState.orderType === 'wedding'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.WEDDING.EVENT-SIZE")}</h2>
                                }
                                {
                                    formState.orderType === 'baptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.BAPTISM.EVENT-SIZE")}</h2>
                                }
                                {
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.WEDDING-BAPTISM.EVENT-SIZE")}</h2>
                                }
                                <h5>{t("HOME.SUB-HEADERS.RADIO-GROUP")}</h5>
                            </div>

                            {/* Here the user chooses the size of their event. */}
                            <div id="photography-event-size" className="option-group">
                                {/* Under 200 people */}
                                <BoxButton 
                                    buttonClicked = {photographyEventSizeChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-small-size"
                                    buttonName = "photography-event-size"
                                    buttonValue = "small"
                                    popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM-PHOTOGRAPHY.EVENT-SIZE.SMALL")}
                                    labelValue = {t("HOME.LABELS.EVENT-SIZE.SMALL")}
                                    price = {`${prices.weddingPrices.photography.eventSize.small}€`}
                                >
                                </BoxButton>

                                {/* Around 200 to 400 people */}
                                <BoxButton 
                                    buttonClicked = {photographyEventSizeChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-medium-size"
                                    buttonName = "photography-event-size"
                                    buttonValue = "medium"
                                    popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM-PHOTOGRAPHY.EVENT-SIZE.MEDIUM")}
                                    labelValue = {t("HOME.LABELS.EVENT-SIZE.MEDIUM")}
                                    price = {`${prices.weddingPrices.photography.eventSize.medium}€`}
                                >
                                </BoxButton>
                                
                                {/* More than 400 people */}
                                <BoxButton 
                                    buttonClicked = {photographyEventSizeChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-large-size"
                                    buttonName = "photography-event-size"
                                    buttonValue = "large"
                                    popoverValue = {t("HOME.POPOVERS.WEDDING-BAPTISM-PHOTOGRAPHY.EVENT-SIZE.LARGE")}
                                    labelValue = {t("HOME.LABELS.EVENT-SIZE.LARGE")}
                                    price = {`${prices.weddingPrices.photography.eventSize.large}€`}
                                >
                                </BoxButton>

                            </div>
                        </div>
                        

                        <div className="form-section">
                            <div className="header-block">
                                {
                                    formState.orderType === 'wedding'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.WEDDING.PACKAGE")}</h2>
                                }
                                {
                                    formState.orderType === 'baptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.BAPTISM.PACKAGE")}</h2>
                                }
                                {
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.WEDDING-BAPTISM.PACKAGE")}</h2>
                                }
                                <h5>{t("HOME.SUB-HEADERS.CHECKBOX-GROUP")}</h5>
                            </div>
                            {/* Here the user chooses the package options to better determine the price. */}
                            <div id="photography-package" className="option-group">
                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'wedding'
                                    &&
                                    <>
                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-preparations"
                                            buttonName = "photography-package"
                                            buttonValue = "preparations"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-PHOTOGRAPHY.PACKAGE.PREPARATIONS")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PREPARATIONS")}
                                            price = {`${prices.weddingPrices.photography.package.preparations.min} - ${prices.weddingPrices.photography.package.preparations.max}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-party"
                                            buttonName = "photography-package"
                                            buttonValue = "party"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-PHOTOGRAPHY.PACKAGE.PARTY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PARTY")}
                                            price = {`${prices.weddingPrices.photography.package.party}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-next-day"
                                            buttonName = "photography-package"
                                            buttonValue = "nextDay"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-PHOTOGRAPHY.PACKAGE.NEXT-DAY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.NEXT-DAY")}
                                            price = {`${prices.weddingPrices.photography.package.nextDay.min}€ - ${prices.weddingPrices.photography.package.nextDay.max}€`}
                                        >
                                        </BoxButton>
                                    </>
                                }

                                {/* Include the scenes of the family at their home with the baby */
                                    formState.orderType === 'baptism'
                                    &&
                                    <>
                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-family-portrait"
                                            buttonName = "photography-package"
                                            buttonValue = "family"
                                            popoverValue =  {t("HOME.POPOVERS.BAPTISM-PHOTOGRAPHY.PACKAGE.FAMILY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PHOTOGRAPHY-FAMILY")}
                                            price = {`${prices.baptismPrices.photography.package.family}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-party"
                                            buttonName = "photography-package"
                                            buttonValue = "party"
                                            popoverValue =  {t("HOME.POPOVERS.BAPTISM-PHOTOGRAPHY.PACKAGE.PARTY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PARTY")}
                                            price = {`${prices.baptismPrices.photography.package.party}€`}
                                        >
                                        </BoxButton>
                                    </>
                                }

                                {
                                    /* Include the preparations before the main event */
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <>
                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-preparations"
                                            buttonName = "photography-package"
                                            buttonValue = "preparations"
                                            popoverValue =  {
                                                t("HOME.POPOVERS.WEDDING-PHOTOGRAPHY.PACKAGE.PREPARATIONS")
                                            }
                                            labelValue = {t("HOME.LABELS.PACKAGE.PREPARATIONS")}
                                            price = {`${prices.weddingWithBaptismPrices.photography.package.preparations.min} - ${prices.weddingWithBaptismPrices.photography.package.preparations.max}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-party"
                                            buttonName = "photography-package"
                                            buttonValue = "party"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM-PHOTOGRAPHY.PACKAGE.PARTY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.PARTY")}
                                            price = {`${prices.weddingWithBaptismPrices.photography.package.party}€`}
                                        >
                                        </BoxButton>

                                        <BoxButton 
                                            buttonClicked = {photographyPackageChanged}
                                            buttonType = "checkbox"
                                            buttonID = "photography-next-day"
                                            buttonName = "photography-package"
                                            buttonValue = "nextDay"
                                            popoverValue =  {t("HOME.POPOVERS.WEDDING-BAPTISM-PHOTOGRAPHY.PACKAGE.NEXT-DAY")}
                                            labelValue = {t("HOME.LABELS.PACKAGE.NEXT-DAY")}
                                            price = {`${prices.weddingWithBaptismPrices.photography.package.nextDay.min}€ - ${prices.weddingWithBaptismPrices.photography.package.nextDay.max}€`}
                                        >
                                        </BoxButton>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="form-section">
                            <div className="header-block">
                                {
                                    formState.orderType === 'wedding'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.WEDDING.ALBUM")}</h2>
                                }
                                {
                                    formState.orderType === 'baptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.BAPTISM.ALBUM")}</h2>
                                }
                                {
                                    formState.orderType === 'weddingWithBaptism'
                                    &&
                                    <h2>{t("HOME.HEADERS.PHOTOGRAPHY-OPTIONS.WEDDING-BAPTISM.ALBUM")}</h2>
                                }
                                <h5>{t("HOME.SUB-HEADERS.RADIO-GROUP")}</h5>
                            </div>

                            {/* Here the user choose if they a photo-album or not */}
                            <div id="photography-album" className="option-group">
                                {/* No photo-album. Just the images with some touch-up. */}
                                <BoxButton 
                                    buttonClicked = {photographyAlbumChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-no-album"
                                    buttonName = "photography-album"
                                    buttonValue = "noAlbum"
                                    popoverValue = {t("HOME.POPOVERS.ALBUM.NO-ALBUM")}
                                    labelValue = {t("HOME.LABELS.ALBUM.NO-ALBUM")}
                                    price = {`${prices.baptismPrices.photography.album.noAlbum}€`}
                                >
                                </BoxButton>

                                {/* A sparse photo album of the entire event */}
                                <BoxButton 
                                    buttonClicked = {photographyAlbumChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-sparse"
                                    buttonName = "photography-album"
                                    buttonValue = "sparseAlbum"
                                    popoverValue = {t("HOME.POPOVERS.ALBUM.SPARSE") + t("HOME.POPOVERS.ALBUM.EXTRA-PAGES")}
                                    imageHeader = {t("HOME.POPOVERS.ALBUM.IMAGE-HEADERS.SPARSE")}
                                    popoverImage = "/media/images/album/Sparse/2.jpg"
                                    labelValue = {t("HOME.LABELS.ALBUM.SPARSE")}
                                    price = {`${prices.baptismPrices.photography.album.sparseAlbum}€`}
                                >
                                </BoxButton>

                                {/* An medium desnsity photo album of the entire event */}
                                <BoxButton 
                                    buttonClicked = {photographyAlbumChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-medium-density"
                                    buttonName = "photography-album"
                                    buttonValue = "mediumAlbum"
                                    popoverValue = {t("HOME.POPOVERS.ALBUM.MEDIUM") + t("HOME.POPOVERS.ALBUM.EXTRA-PAGES")}
                                    imageHeader = {t("HOME.POPOVERS.ALBUM.IMAGE-HEADERS.MEDIUM")}
                                    popoverImage = "/media/images/album/Medium/1.jpg"
                                    labelValue = {t("HOME.LABELS.ALBUM.MEDIUM")}
                                    price = {`${prices.baptismPrices.photography.album.mediumAlbum}€`}
                                >
                                </BoxButton>
                                
                                {/* A dense photo album of the entire event */}
                                <BoxButton 
                                    buttonClicked = {photographyAlbumChanged}
                                    buttonType = "radio"
                                    buttonID = "photography-dense"
                                    buttonName = "photography-album"
                                    buttonValue = "denseAlbum"
                                    popoverValue = {t("HOME.POPOVERS.ALBUM.DENSE") + t("HOME.POPOVERS.ALBUM.EXTRA-PAGES")}
                                    imageHeader = {t("HOME.POPOVERS.ALBUM.IMAGE-HEADERS.DENSE")}
                                    popoverImage = "/media/images/album/Dense/1.jpg"
                                    labelValue = {t("HOME.LABELS.ALBUM.DENSE")}
                                    price = {`${prices.baptismPrices.photography.album.denseAlbum}€`}
                                >
                                </BoxButton>

                                <DirectionsNotice />
                            </div>
                        </div>
                    </div>
                }

            </div>

        )
    }
}

export default withTranslation()(TypicalOrder);