import {useState} from "react";
import { useTranslation } from "react-i18next";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {Helmet} from "react-helmet-async";

const LanguageSelector = () => {

    const { i18n, t } = useTranslation();
    const [currentLang, setLang] = useState(i18n.language)

    const changeLanguage = (selectedLanguage) => {

        i18n.changeLanguage(selectedLanguage);
        setLang(selectedLanguage);

        const mailingList = document.getElementById('mailing-list');
        if(mailingList) {
            // Set the mailing-list's before content in the selected language.
            mailingList.style.setProperty('--button-content', `"${t("HOME.VALUES.MAILING-LIST")}"`);
        }
    };

    return (
        <div>
            <Helmet htmlAttributes={{ lang : currentLang }}/>
            <DropdownButton id="dropdown-basic-button" title={(currentLang.startsWith("en")) ? "EN" : "ΕΛ"} onSelect={changeLanguage}>
                <Dropdown.Item eventKey="en" active={currentLang === "en"}>English</Dropdown.Item>
                <Dropdown.Item eventKey="el" active={currentLang === "el"}>Ελληνικά</Dropdown.Item>
            </DropdownButton>
        </div>
    );

};


export default LanguageSelector;