import Sample from "./Sample";
import './Samples.scss';
import { withTranslation } from 'react-i18next';
// import { useEffect } from "react";

const Samples = (props) => {

    const {t} = props;
    
    const Samples = [
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/1.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/2.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/3.jpg"
        },

        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.VIDEO"),
            "type": "video",
            "source": "/media/videos/drone.mp4"
        },

        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/4.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/5.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/6.jpg"
        },

        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/7.jpg"
        },

        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.VIDEO"),
            "type": "video",
            "source": "/media/videos/baptism.mp4"
        },


        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/8.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/9.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/10.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/11.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/12.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/13.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/14.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/15.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/16.jpg"
        },

        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/17.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/18.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/19.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/20.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/21.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/22.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/23.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/24.jpg"
        },
        {
            "modalHeader": t("SAMPLES.SUB-HEADERS.PHOTO"),
            "type": "photo",
            "source": "/media/images/samples/25.jpg"
        }
    ]

    return (
        <>
            <div className="main-container">
                <div className="header-block">
                    <h2>{t("SAMPLES.HEADERS.OUR-WORK")}</h2>
                    <h5>{t("SAMPLES.SUB-HEADERS.OUR-WORK")}</h5>
                </div>

                <div id="gallery" className="container-fluid">
                    {
                        Samples.map((item, index) => 
                            (<Sample key={index} item={item}></Sample>)  
                        )
                    }  
                </div>
            </div>

            <div className="main-container">
                <div className="header-block">
                    <h2>{t("SAMPLES.HEADERS.ALBUM.DENSE")}</h2>
                    <h5>{t("HOME.POPOVERS.ALBUM.DENSE")}</h5>
                </div>

                <div className="album-samples">
                    <img className="album-sample" src="/media/images/album/Dense/1.jpg" alt="Dense digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Dense/2.jpg" alt="Dense digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Dense/3.jpg" alt="Dense digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Dense/4.jpg" alt="Dense digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Dense/5.jpg" alt="Dense digital album double-page sample" />
                </div>
            </div>
            <div className="main-container">
                <div className="header-block">
                    <h2>{t("SAMPLES.HEADERS.ALBUM.MEDIUM")}</h2>
                    <h5>{t("HOME.POPOVERS.ALBUM.MEDIUM")}</h5>
                </div>

                <div className="album-samples">
                    <img className="album-sample" src="/media/images/album/Medium/1.jpg" alt="Medium density digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Medium/2.jpg" alt="Medium density digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Medium/3.jpg" alt="Medium density digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Medium/4.jpg" alt="Medium density digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Medium/5.jpg" alt="Medium density digital album double-page sample" />
                </div>
            </div>
            <div className="main-container">
                <div className="header-block">
                    <h2>{t("SAMPLES.HEADERS.ALBUM.SPARSE")}</h2>
                    <h5>{t("HOME.POPOVERS.ALBUM.SPARSE")}</h5>
                </div>

                <div className="album-samples">
                    <img className="album-sample" src="/media/images/album/Sparse/1.jpg" alt="Sparse digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Sparse/2.jpg" alt="Sparse digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Sparse/3.jpg" alt="Sparse digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Sparse/4.jpg" alt="Sparse digital album double-page sample" />
                    <img className="album-sample" src="/media/images/album/Sparse/5.jpg" alt="Sparse digital album double-page sample" />
                </div>
            </div>
        </>
    )
}

export default withTranslation()(Samples);