import React, { Component } from 'react';

class VisibilityDetector extends Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
    }

    componentDidMount() {

        // Initialize the observer for the form section.
        if(this.elementRef.current.children[0].classList.contains("form-section")) {
            this.observer = new IntersectionObserver(
                (entries) => {
                    const isVisible = entries[0].isIntersecting;
                    this.props.onVisibilityChange(isVisible);
                },
                {
                    // Modify the threshold value to your needs (0.5 means at least 50% 
                    // of the element should be visible)
                    threshold: 0.7,
                }
            );
        }

        // Initialize the observer for the footer.
        if(this.elementRef.current.children[0].classList.contains("footer")) {
            this.observer = new IntersectionObserver(
                (entries) => {
                    const isVisible = entries[0].isIntersecting;
                    this.props.onVisibilityChange(isVisible);
                },
                {
                    // Modify the threshold value to your needs (0.5 means at least 50% 
                    // of the element should be visible)
                    threshold: 0.01,
                }
            );
        }


        if (this.elementRef.current) {
            this.observer.observe(this.elementRef.current);
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    render() {
        return (
            <div ref={this.elementRef} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export default VisibilityDetector;
