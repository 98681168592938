import { Component } from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class PriceTab extends Component {

    componentDidUpdate(prevProps, prevState) {

        // Check if the user has changed order type.
        if(
            (prevProps.formState.minVideoPrice !== this.props.formState.minVideoPrice)
            ||
            (prevProps.formState.minPhotographyPrice !== this.props.formState.minPhotographyPrice)
            ||
            (prevProps.formState.minExtrasPrice !== this.props.formState.minExtrasPrice)
        ) {
            // Get the price tag from the DOM.
            const priceTag = document.getElementById('price-tag');
    
            // Check if the price tag is in the DOM.
            if(priceTag) {
                // Upscale the element.
                priceTag.style.transform = 'scale(1.5, 1.5)';
                priceTag.style.backgroundColor = '#f6fcd7b8';
                
                setTimeout(() => {  
                    // Downscale the element.
                    priceTag.style.transform = 'scale(1, 1)';
                    priceTag.style.backgroundColor = 'transparent';
                }, 300);
            
            }
        }
    }
    
    render () {
        const {t, formState} = this.props;

        let minPrice = formState.minVideoPrice + formState.minPhotographyPrice + formState.minExtrasPrice;
        let maxPrice = minPrice + formState.maxVideoPrice + formState.maxPhotographyPrice + formState.maxExtrasPrice;

        return(
            <div id="price-container">

                {
                    // Check if the user is on the home page.
                    (formState.currentURL !== '/')
                    && 
                    <>
                        {
                            (
                                formState.minVideoPrice === null
                                &&
                                formState.minPhotographyPrice === null
                                &&
                                formState.minExtrasPrice === null
                            ) 
                            &&
                            <span>
                                {t("PRICE-TAB.GO-HOME")} <Link to="/" className="price-tab-link">{t("PRICE-TAB.HOME-PAGE")}</Link>
                            </span>
                        }

                        {
                            (
                                (
                                    formState.minVideoPrice === 0
                                    &&
                                    formState.minPhotographyPrice === 0
                                    &&
                                    formState.minExtrasPrice === 0
                                )
                                ||
                                (
                                    formState.minVideoPrice > 0
                                    ||
                                    formState.minPhotographyPrice > 0
                                    ||
                                    formState.minExtrasPrice > 0
                                )
                            )
                            &&
                            <span>
                                {t("PRICE-TAB.RETURN-HOME")} <Link to="/" className="price-tab-link">{t("PRICE-TAB.HOME-PAGE")}</Link>
                            </span>
                        }
                    </>
                }

                {
                    // Check if the user is on the home page.
                    (formState.currentURL === '/')
                    &&
                    <>
                        {
                            (formState.orderType === null && formState.orderTypeVisibility === false && formState.footerVisibility === false)
                            &&
                            <div id="left-arrow" className="scroll-down-link scroll-down-arrow"></div>
                        }

                        {
                            (
                                formState.minVideoPrice === null
                                &&
                                formState.minPhotographyPrice === null
                                &&
                                formState.minExtrasPrice === null
                            )
                            &&
                            (
                                <span id="price-tab-step-1">
                                    {t("PRICE-TAB.STEP-1")}
                                </span>
                            )
                        }

                        {
                            (formState.orderType === null && formState.orderTypeVisibility === false && formState.footerVisibility === false)
                            &&
                            <div id="right-arrow" className="scroll-down-link scroll-down-arrow"></div>
                        }

                        {
                            (
                                formState.minVideoPrice === 0
                                &&
                                formState.minPhotographyPrice === 0
                                &&
                                formState.minExtrasPrice === 0
                            ) 
                            &&
                            <span>
                                {t("PRICE-TAB.STEP-2")}
                            </span>
                        }

                        {
                            (
                                formState.minVideoPrice > 0
                                ||
                                formState.minPhotographyPrice > 0
                                ||
                                formState.minExtrasPrice > 0
                            ) 
                            &&
                            <span>

                                <span id="price-tag">
                                    {minPrice}€ 
                                    
                                    {
                                        (
                                            formState.maxVideoPrice > 0
                                            ||
                                            formState.maxPhotographyPrice > 0
                                            ||
                                            formState.maxExtrasPrice > 0
                                        )
                                        &&
                                        <>&nbsp; - &nbsp;{maxPrice}€</>
                                    }


                                </span> 

                                {
                                    (
                                        formState.orderType !== 'custom'
                                    )
                                    &&
                                    <>&nbsp; {t("PRICE-TAB.TOTAL-COST")}</>
                                }

                                {
                                    (
                                        formState.orderType === 'custom'
                                    )
                                    &&
                                    <>&nbsp; <u>{t("PRICE-TAB.MINIMUM-COST")}</u> </>
                                }

                                {
                                    (formState.location === "other")
                                    &&
                                    <> + {t("PRICE-TAB.TRAVEL-EXPENSES")}</>
                                }
                            </span>
                        }
                    </>
                }

            </div>
        )
        
    }
}

export default withTranslation()(PriceTab);