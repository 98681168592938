import { Component } from "react";
import { withTranslation } from 'react-i18next';

class Team extends Component {

    render () {
        const {t} = this.props;
        

        return (
            <div className="main-container">
                <div className="header-block">
                    <h2>{t("PRIVACY-POLICY.HEADERS.PAGE-HEADER")}</h2>
                    {/* <h5>{t("PRIVACY-POLICY.HEADERS.PAGE-SUBHEADER")}</h5> */}
                </div>
                <div className="policy-content">
                    <h5>{t("PRIVACY-POLICY.HEADERS.INTRO")}</h5>
                    <p>{t("PRIVACY-POLICY.CONTENT.INTRO")}</p>

                    <br />

                    <h5>{t("PRIVACY-POLICY.HEADERS.COLLECTION")}</h5>
                    <h6>{t("PRIVACY-POLICY.CONTENT.COLLECTION.PRIVACY.SUB-HEADER")}</h6>
                    <p>{t("PRIVACY-POLICY.CONTENT.COLLECTION.PRIVACY.EXPLANATION")}</p>
                    <ul>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.PRIVACY.FULL-NAME")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.PRIVACY.EMAIL")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.PRIVACY.PHONE")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.PRIVACY.ADDRESS")}</li>
                    </ul>

                    <br />

                    <h6>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.SUB-HEADER")}</h6>
                    <p>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.EXPLANATION")}</p>
                    <ul>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.DATETIME")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.PAGES")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.SERVICES")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.IP")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.LOCATION")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.BROWSER")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.COOKIES")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.COLLECTION.OTHER-DATA.INFO")}</li>
                    </ul>

                    <br />

                    <h5>{t("PRIVACY-POLICY.HEADERS.PERSONAL-DATA")}</h5>
                    <p>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.EXPLANATION")}</p>

                    <ul>
                        <li>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.CONTRACTUAL")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.LEGAL-OBLIGATION")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.COMMERCIAL")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.LEGAL-INTEREST")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.PROMOTIONAL")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PERSONAL-DATA.RESPOND")}</li>
                    </ul>

                    <br />

                    <h5>{t("PRIVACY-POLICY.HEADERS.PROVISION")}</h5>
                    <p>{t("PRIVACY-POLICY.CONTENT.PROVISION.EXPLANATION-1")}</p>
                    <ul>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.CREDIT")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.COOKIES")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.FORMSUBMIT")}</li>
                    </ul>
                    <p>{t("PRIVACY-POLICY.CONTENT.PROVISION.EXPLANATION-2")}</p>
                    <ul>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.REQUEST")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.UPDATE")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.WITHDRAW")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.OBJECT")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.CORRECT")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROVISION.REQUEST-FILE")}</li>
                    </ul>

                    <br />

                    <h5>{t("PRIVACY-POLICY.HEADERS.PROTECTION")}</h5>
                    <p>{t("PRIVACY-POLICY.CONTENT.PROTECTION.EXPLANATION-1")}</p>
                    <ul>
                        <li>
                            {t("PRIVACY-POLICY.CONTENT.PROTECTION.GOOGLE-ANALYTICS.PARAGRAPH-1")} 
                            <a href="https://support.google.com/analytics/answer/6004245">
                                {t("PRIVACY-POLICY.CONTENT.PROTECTION.GOOGLE-ANALYTICS.LINK-1")}
                            </a>
                            {t("PRIVACY-POLICY.CONTENT.PROTECTION.GOOGLE-ANALYTICS.PARAGRAPH-2")} 
                            <a href="https://policies.google.com/privacy">
                                {t("PRIVACY-POLICY.CONTENT.PROTECTION.GOOGLE-ANALYTICS.LINK-2")}
                            </a>
                        </li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROTECTION.FORMSUBMIT")}</li>
                        <li>{t("PRIVACY-POLICY.CONTENT.PROTECTION.DATA-HANDLING")}</li>
                    </ul>
                    <p>{t("PRIVACY-POLICY.CONTENT.PROTECTION.EXPLANATION-2")}</p>

                    <br />

                    <h5>{t("PRIVACY-POLICY.HEADERS.CONTACT")}</h5>
                    <div>
                        {t("PRIVACY-POLICY.CONTENT.CONTACT.EXPLANATION-1")}
                    </div>
                    <div className="quote-block">
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.TITLE-1")}
                        </div>
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.ADDRESS-1")}
                        </div>
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.EMAIL-1")}
                        </div>
                    </div>
                    <br />
                    <div>
                        {t("PRIVACY-POLICY.CONTENT.CONTACT.EXPLANATION-2")}
                    </div>
                    <div className="quote-block">
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.TITLE-2")}
                        </div>
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.ADDRESS-2")}
                        </div>
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.PHONE-2")}
                        </div>
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.FAX-2")}
                        </div>
                        <div>
                            {t("PRIVACY-POLICY.CONTENT.CONTACT.EMAIL-2")}
                        </div>
                    </div>
                        
                    <br />
                        
                    <h5>{t("PRIVACY-POLICY.HEADERS.COOKIES")}</h5>
                    <p>{t("PRIVACY-POLICY.CONTENT.COOKIES.EXPLANATION")}</p>
                    
                    <h6>{t("PRIVACY-POLICY.CONTENT.COOKIES.PERFORMANCE-HEADER")}</h6>
                    <p>{t("PRIVACY-POLICY.CONTENT.COOKIES.PERFORMANCE-CONTENT")}</p>
                    
                    <h6>{t("PRIVACY-POLICY.CONTENT.COOKIES.FUNCTIONALITY-HEADER")}</h6>
                    <p>{t("PRIVACY-POLICY.CONTENT.COOKIES.FUNCTIONALITY-CONTENT")}</p>
                    <p>{t("PRIVACY-POLICY.CONTENT.COOKIES.DISABLE")}</p>

                    <h6>{t("PRIVACY-POLICY.CONTENT.COOKIES.DELETION-HEADER")}</h6>
                    <p>{t("PRIVACY-POLICY.CONTENT.COOKIES.DELETION-CONTENT")}</p>

                    <table>
                        <tbody>
                            <tr>
                                <th>{t("PRIVACY-POLICY.CONTENT.COOKIES.COOKIE-LIST.COOKIE")}</th>
                                <th>{t("PRIVACY-POLICY.CONTENT.COOKIES.COOKIE-LIST.DESCRIPTION")}</th>
                            </tr>
                            <tr>
                                <td>{t("PRIVACY-POLICY.CONTENT.COOKIES.COOKIE-LIST.COOKIE-1-TITLE")}</td>
                                <td>{t("PRIVACY-POLICY.CONTENT.COOKIES.COOKIE-LIST.COOKIE-1-DESCRIPTION")}</td>
                            </tr>
                            <tr>
                            <td>{t("PRIVACY-POLICY.CONTENT.COOKIES.COOKIE-LIST.COOKIE-2-TITLE")}</td>
                                <td>{t("PRIVACY-POLICY.CONTENT.COOKIES.COOKIE-LIST.COOKIE-2-DESCRIPTION")}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        )
    }
}

export default withTranslation()(Team);