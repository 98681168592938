import React from 'react';
import { Overlay } from 'react-bootstrap';

const CriticalTip = ({ target, content }) => {
  
  return (
    <Overlay target={target} show={true} placement="top">
      {({ placement, arrowProps, show, popper, hasDoneInitialMeasure, ...props }) => (
        <div
          {...props}
          className={`tooltip bs-tooltip-${placement} show critical-tip`}
          style={{ ...props.style }}
        >
          <div className="tooltip-arrow" {...arrowProps} />
          <div className="tooltip-inner">{content}</div>
        </div>
      )}
    </Overlay>
  );
};

export default CriticalTip;
