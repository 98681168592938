import { Component } from "react";
import BoxButton from "../../Layout/BoxButton";
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';

class ContactInfo extends Component {

    

    componentDidMount() {
        const mailingList = document.getElementById('mailing-list');
        if(this.props.formState.mailingList) {
            mailingList.checked = true;
        }
        // Set the mailing-list's before content in the selected language.
        mailingList.style.setProperty('--button-content', `"${this.props.t("HOME.VALUES.MAILING-LIST")}"`);

    }

    render () {
        const {t, formState, locationChanged, nameChanged, emailChanged, phoneChanged, dateChanged, mailingListChanged} = this.props
        // Get today's date.
        let today = new Date();
        // Initialize the 3-days-later date to today.
        let threeDaysLater = new Date();
        // Add 3 days to the 3-days-later date.
        threeDaysLater.setDate(today.getDate() + 3);
        // Format the 3-days-later date into a string that is readable from the date picker input.
        const formatedDate = threeDaysLater.toLocaleDateString("ja", {year:"numeric", month:"2-digit", day:"2-digit"}).replaceAll('/', '-');

        return (
            <>
                <div className="form-section">
                    <div className="header-block">
                        <h2>{t("HOME.HEADERS.LOCATION")}</h2>
                        <h5>{t("HOME.SUB-HEADERS.RADIO-GROUP")}</h5>
                    </div>
                
                    {/* Here the user chooses the add-on services that they want */}
                    <div id="location" className="option-group">  
                                    
                        <BoxButton 
                            buttonClicked = {locationChanged}
                            buttonType = "radio"
                            buttonID = "thessaloniki"
                            buttonName = "extras"
                            buttonValue = "thessaloniki"
                            popoverValue =  {t("HOME.POPOVERS.LOCATION.THESSALONIKI")}
                            labelValue = {t("HOME.LABELS.LOCATION.THESSALONIKI")}
                            price = "0€"
                        >
                        </BoxButton>
                    
                        {/* Note that travel expenses will be affected by crew size and location */}
                        <BoxButton 
                            buttonClicked = {locationChanged}
                            buttonType = "radio"
                            buttonID = "other"
                            buttonName = "extras"
                            buttonValue = "other"
                            popoverValue =  {t("HOME.POPOVERS.LOCATION.OTHER")}
                            labelValue = {t("HOME.LABELS.LOCATION.OTHER")}
                            price = {t("HOME.VALUES.TRAVEL-EXPENSES")}
                        >
                        </BoxButton>
                        
                    </div>
                </div>
                
                <div className="form-section">
                    <div className="header-block">
                        <h2>{t("HOME.HEADERS.CONTACT")}</h2>
                        <h5>{t("HOME.SUB-HEADERS.CONTACT")}</h5>
                    </div>
                    
                    <div className="letter-input-container">
                        <label className="letter-input-label" htmlFor="full-name">{t("HOME.LABELS.CONTACT.NAME")}</label>
                        <Form.Control 
                            id="full-name" 
                            className="letter-input"
                            name="full-name" 
                            type="text" 
                            placeholder={t("HOME.VALUES.NAME")}
                            value={formState.name} 
                            onChange={nameChanged} 
                            required 
                        />
                    </div>

                    <div className="letter-input-container">
                        <label className="letter-input-label" htmlFor="email">{t("HOME.LABELS.CONTACT.EMAIL")}</label>
                        <Form.Control 
                            id="email" 
                            className="letter-input"
                            name="email" 
                            type="email" 
                            placeholder="your@email.com" 
                            value={formState.email} 
                            onChange={emailChanged} 
                            required 
                        />
                    </div>

                    <div className="letter-input-container">
                        <label className="letter-input-label" htmlFor="phone">{t("HOME.LABELS.CONTACT.PHONE")}</label>
                        <Form.Control 
                            id="phone" 
                            className="letter-input"
                            name="phone" 
                            type="text" 
                            placeholder="+30-6900000000"
                            value={formState.phone} 
                            onChange={phoneChanged}
                            required 
                        />
                    </div>

                    <div className="letter-input-container">
                        <label className="letter-input-label" htmlFor="date">{t("HOME.LABELS.CONTACT.DATE")}</label>
                        <Form.Control 
                            id="date" 
                            className="letter-input"
                            name="date" 
                            type="date"
                            lang={t("HOME.VALUES.LANG")} 
                            min={formatedDate}
                            value={formState.date} 
                            onChange={dateChanged}
                            pattern="\d{2}-\d{2}-\d{4}"
                            required
                        />
                    </div>

                    <div className="letter-input-container">
                        <label className="letter-input-label" htmlFor="mailing-list">{t("HOME.LABELS.CONTACT.MAILING-LIST")}</label>
                        <input 
                            id="mailing-list"
                            name="mailing-list" 
                            type="checkbox"
                            onClick={mailingListChanged}
                        />
                    </div>

                </div>
            </>

        )
    }
}

export default withTranslation()(ContactInfo);