
import { useTranslation } from "react-i18next";

const Contact = () => {

    const { t } = useTranslation();

    return (
        <div className="main-container">
            <div className="header-block">
                <h2>{t("CONTACT.HEADER")}</h2>
            </div>
            <div className="policy-content">
                <p>{t("CONTACT.DESCRIPTION")}</p>
                <ul>
                    <li>
                        <strong>{t("CONTACT.METHODS.CHAT")}</strong>: <a href="https://www.facebook.com/messages/t/116982796877233">{t("CONTACT.LINKS.CHAT")}</a>
                    </li>
                    <li>
                        <strong>{t("CONTACT.METHODS.EMAIL")}</strong>: <a href="mailto:inhinito@gmail.com">{t("CONTACT.LINKS.EMAIL")}</a>
                    </li>
                    <li>
                        <strong>{t("CONTACT.METHODS.PHONE")}</strong>: <span>{t("CONTACT.LINKS.PHONE")}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Contact