import { Component } from "react";
import MyPopover from "./MyPopover";

class BoxButton extends Component {

    render () {
        const { buttonClicked, buttonType, buttonID, buttonName, buttonValue, popoverValue, imageHeader, popoverImage, labelValue, price} = this.props;

        return (
            <div 
                className="option-block pushable pushable-active"
                onMouseEnter={this.handleHoverStyles} 
                onMouseLeave={this.handleHoverStyles}
            >
                <span className="shadow"></span>
                <span className="edge"></span>
                <MyPopover buttonID={buttonID} popoverValue={popoverValue} imageHeader={imageHeader} popoverImage={popoverImage}></MyPopover>

                <label className="form-label front" htmlFor={buttonID}>
                    <input
                        className="form-checkbox" 
                        onClick={buttonClicked}
                        type={buttonType}
                        id={buttonID}
                        name={buttonName}
                        value={buttonValue}
                    />

                    <span className="label-center">
                        {labelValue}
                    </span>

                    <span className="ribbon">{price}</span>

                </label><br />
            </div>
        );
    }
}
    
export default BoxButton;