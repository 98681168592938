import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CookieConsentBanner = ({ onConsent, onReject }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(!localStorage.getItem('cookieConsent'));

  const handleConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
    onConsent();
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsVisible(false);
    onReject();
  };

  return (
    isVisible && (
      <div className="cookie-consent-banner">
        <p>
          {t("PRIVACY-POLICY.CONSENT-BANNER.NOTICE")} <Link to="/privacy-policy">{t("PRIVACY-POLICY.HEADERS.PAGE-HEADER")}</Link>.
        </p>
        <div className="cookie-consent-banner__buttons">
          <button onClick={handleReject}>{t("PRIVACY-POLICY.CONSENT-BANNER.NO")}</button>
          <button onClick={handleConsent} id="yes-button">{t("PRIVACY-POLICY.CONSENT-BANNER.YES")}</button>
        </div>
      </div>
    )
  );
};

export default CookieConsentBanner;
