import { Component } from "react";
import { withTranslation } from 'react-i18next';

import './OrderForm.css';
import OrderBasics from "./OrderBasics";
import TypicalOrder from "./TypicalOrder";
import CustomOrder from "./CustomOrder";
import OrderExtras from "./OrderExtras";
import ContactInfo from "./ContactInfo";
import MyTooltip from "../../Layout/MyTooltip";

import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { trackEvent } from '../../../analytics';
import LandingCarousel from "../../Layout/LandingCarousel";

class OrderForm extends Component {

    state = {
        modalOpen: false,
        loadingRing: true,
        loadingHeader: this.props.t("HOME.FORM-SUBMIT.LOADING-HEADER"),
        successHeader: null,
        successMessage: null,
        failureHeader: null,
        failureMessage: null,
        submittable: false
    }

    /**
     * Check a radio button checkbox based on a selected state value.
     * @param {*} optionGroup the HTML element that holds all of the different options.
     * @param {*} stateValue the selected state value.
     */
    checkRadioButton(optionGroup, stateValue) {
        // Loop through the children of the option group.
        for (let i = 0; i < optionGroup.childNodes.length; i++) {
            // Get the different childNodes in the option group.
            const radioOptions = optionGroup.childNodes[i];
            // Get the checkbox elements of the children.
            const radioCheckbox = radioOptions.getElementsByClassName('form-checkbox')[0];

            // Check if the checkbox value maches the currently selected value in the state.
            if(radioCheckbox && radioCheckbox.value === stateValue) {
                // Select the radio button checkbox.
                radioCheckbox.checked = true;
            }
        }
    }

    /**
     * Check the checkbox buttons that are already selected in the state.
     * @param {*} optionGroup the HTML element that holds all of the different options.
     * @param {*} stateValues the selected state values in the optionGroup.
     */
    checkCheckboxButtons(optionGroup, stateValues) {
        // Check if there are any selected values in the checkbox option group.
        if(stateValues.size > 0) {
            // Loop through the children of the option group.
            for (let i = 0; i < optionGroup.children.length; i++) {
                // Get the different children in the option group.
                const checkboxOptions = optionGroup.children[i];
                // Get the checkbox elements of the children.
                const checkbox = checkboxOptions.querySelector('.form-checkbox');

                // Check if the child element is actually a checkbox (it might be a directions notice for example).
                if(checkbox) {
                    // Loop through the selected state values.
                    for (const value of stateValues.values()) {
                        // Check if the checkbox value maches the currently selected value in the state.
                        if(checkbox && checkbox.value === value) {
                            // Select the checkbox button.
                            checkbox.checked = true;
                        }
                    }
                }
            }
        }
    }


    componentDidMount(){

        if(this.props.formState.orderType) {
            // Get the option group that we want to select according to the state.
            const orderType = document.getElementById('order-type');
            // Check the radio button that matches the current state.
            this.checkRadioButton(orderType, this.props.formState.orderType);
        }

        
        const services = document.getElementById('services');
        if(services) {
            // Check the checkbox buttons that match the current state.
            this.checkCheckboxButtons(services, this.props.formState.services);

            if(this.props.formState.services.has('video')){
                const videoPackage = document.getElementById('video-package');
                if(videoPackage) {
                    // Check the checkbox buttons that match the current state.
                    this.checkCheckboxButtons(videoPackage, this.props.formState.video.package);
                }
    
                const videoCoverage = document.getElementById('video-coverage');
                if(videoCoverage) {
                    // Check the checkbox buttons that match the current state.
                    this.checkRadioButton(videoCoverage, this.props.formState.video.coverage);
                }
            }
    
            if(this.props.formState.services.has('photography')){
                const photographyEventSize = document.getElementById('photography-event-size');
                if(photographyEventSize) {
                    // Check the checkbox buttons that match the current state.
                    this.checkRadioButton(photographyEventSize, this.props.formState.photography.eventSize);
                }
                
                const photographyPackage = document.getElementById('photography-package');
                if(photographyPackage) {
                    // Check the checkbox buttons that match the current state.
                    this.checkCheckboxButtons(photographyPackage, this.props.formState.photography.package);
                }
    
                const photographyAlbum = document.getElementById('photography-album');
                if(photographyAlbum) {
                    // Check the checkbox buttons that match the current state.
                    this.checkRadioButton(photographyAlbum, this.props.formState.photography.album);
                }
            }

            if(this.props.formState.orderType === 'custom') {
                const description = document.getElementById('description');
                description.innerText = this.props.formState.description;

                const exampleURLs = document.getElementById('example-urls');
                exampleURLs.innerText = this.props.formState.exampleURLs;
            }

            if(this.props.formState.extras.size !== 0) {
                const extras = document.getElementById('extras');
                // Check the checkbox buttons that match the current state.
                this.checkCheckboxButtons(extras, this.props.formState.extras);
            }

            if(services || (this.props.formState.orderType === 'custom')) {

                const location = document.getElementById('location');   
                if(location) {
                    // Check the checkbox buttons that match the current state.
                    this.checkRadioButton(location, this.props.formState.location);
                }

                const name = document.getElementById('name');
                if(name) {
                    name.value = this.props.formState.name;
                }

                const email = document.getElementById('email');
                if(email) {
                    email.value = this.props.formState.email;
                }

                const phone = document.getElementById('phone');
                if(phone) {
                    phone.value = this.props.formState.phone;
                }

                const date = document.getElementById('date');
                if(date) {
                    date.value = this.props.formState.date;
                }

                const mailingList = document.getElementById('mailing-list');
                if(this.props.formState.mailingList) {
                    mailingList.checked = true;
                }
            }
        }
    }
    
    /**
     * Construct the body of the order's post request.
     */
    constructOrderBody = (formState) => {
        const {
            currentURL,
            orderType,
            services,
            video,
            photography,
            description,
            exampleURLs,
            extras,
            location,
            name,
            email,
            phone,
            date,
            mailingList,
            minVideoPrice,
            minPhotographyPrice,
            minExtrasPrice,
            maxVideoPrice,
            maxPhotographyPrice,
            maxExtrasPrice,
        } = formState;
      
        // Convert sets to arrays for serialization
        const servicesArray = Array.from(services);
        const videoPackagesArray = Array.from(video.package);
        const photographyPackagesArray = Array.from(photography.package);
        const extrasArray = Array.from(extras);
      
        return {
            email,
            name,
            subject: "New order submission",
            message: JSON.stringify({
                currentURL,
                orderType,
                services: servicesArray,
                video: { ...video, package: videoPackagesArray },
                photography: { ...photography, package: photographyPackagesArray },
                description,
                exampleURLs,
                extras: extrasArray,
                location,
                phone,
                date,
                mailingList,
                minVideoPrice,
                minPhotographyPrice,
                minExtrasPrice,
                maxVideoPrice,
                maxPhotographyPrice,
                maxExtrasPrice
            }),
        };
        
    };
      

    submitOrder = (e) => {
        e.preventDefault();
        console.log('Submitted form: ', this.props.formState);

        // Use the track event functions from analytics.js to track the button_click.
        trackEvent('button_click', { event_category: 'example', event_label: 'Button Clicked' });

        // Check if the form is ready for submission.
        if(
            (this.props.formState.services.has('video') || this.props.formState.services.has('photography'))
        ) {
            // Open the modal, submit the order and show a loading ring.
            this.setState({
                modalOpen: true,
                loadingRing: true,
                loadingHeader: this.props.t("HOME.FORM-SUBMIT.LOADING-HEADER"),
                successHeader: null,
                successMessage: null,
                failureHeader: null,
                failureMessage: null
            });

            // Construct the body of the post request.
            const orderBody = this.constructOrderBody(this.props.formState);
            // console.log('The order body: ', orderBody);
    
            // Make a POST request to the FormSubmit.co API.
            fetch("https://formsubmit.co/ajax/f1ee639dcddb5bdac8323bdcb56ee76f", {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(orderBody)
            })
            .then(response => response.json())
            .then(data => {
    
                console.log("The data from formsubmit.co: ", data);
    
                if(!data.success || data.success === 'false') {
                    // Remove the loading ring and show the failure message and header.
                    this.setState({
                        loadingRing: false,
                        loadingHeader: null,
                        failureHeader: this.props.t("HOME.FORM-SUBMIT.FAILURE-HEADER"),
                        failureMessage: this.props.t("HOME.FORM-SUBMIT.FAILURE-MESSAGE")
                    });
                } else  {
                    // Remove the loading ring and show the success message and header.
                    this.setState({
                        loadingRing: false,
                        loadingHeader: null,
                        successHeader: this.props.t("HOME.FORM-SUBMIT.SUCCESS-HEADER"),
                        successMessage: this.props.t("HOME.FORM-SUBMIT.SUCCESS-MESSAGE")
                    });
                }
            })
            .catch(error => {
    
                // Remove the loading ring and show a failure message and header.
                this.setState({
                    loadingRing: false,
                    loadingHeader: null,
                    failureHeader: this.props.t("HOME.FORM-SUBMIT.FAILURE-HEADER"),
                    failureMessage: this.props.t("HOME.FORM-SUBMIT.FAILURE-MESSAGE")
                });
    
                console.log("Got an error: ", error)
            });
        } 

    }
    

    render() { 

        const {
            t,
            prices, 
            formState, 
            orderTypeChanged, 
            servicesChanged, 
            videoPackageChanged,
            videoCoverageChanged, 
            photographyEventSizeChanged,
            photographyPackageChanged,
            photographyAlbumChanged,
            descriptionChanged,
            exampleURLsChanged,
            extrasChanged,
            locationChanged,
            nameChanged,
            emailChanged,
            phoneChanged,
            dateChanged,
            mailingListChanged,
            orderTypeVisibilityChanged
        } = this.props

        
        // console.log('The current state:', formState);
        return (
            <>
                <LandingCarousel />
                
                <form onSubmit={this.submitOrder} >
                    <div className="order-form">

                        <OrderBasics 
                            prices={prices}
                            formState={formState}
                            orderTypeChanged={orderTypeChanged} 
                            servicesChanged={servicesChanged}
                            orderTypeVisibilityChanged={orderTypeVisibilityChanged}
                        ></OrderBasics>

                        {

                            // Check if the user has selected a wedding or baptism order.
                            (
                                formState.orderType === 'wedding'
                                ||
                                formState.orderType === 'baptism'
                                ||
                                formState.orderType === 'weddingWithBaptism'
                            )
                            &&
                            <TypicalOrder 
                                prices={prices}
                                formState={formState}
                                videoPackageChanged={videoPackageChanged}
                                videoCoverageChanged={videoCoverageChanged}
                                photographyEventSizeChanged={photographyEventSizeChanged}
                                photographyPackageChanged={photographyPackageChanged}
                                photographyAlbumChanged={photographyAlbumChanged}
                            ></TypicalOrder>
                        }

                        {
                            // Check if the user has selected the custom order oprtion.
                            (
                                formState.orderType === 'custom'
                            )
                            &&
                            <CustomOrder
                                formState={formState}
                                descriptionChanged={descriptionChanged}
                                exampleURLsChanged={exampleURLsChanged}
                            ></CustomOrder>
                        }

                        {
                            (
                                (formState.orderType === 'custom')
                                ||
                                (formState.services.has('video') || formState.services.has('photography'))
                            )
                            &&
                            <>
                                <OrderExtras 
                                    prices={prices}
                                    formState={formState}
                                    extrasChanged={extrasChanged}
                                ></OrderExtras>
                                <ContactInfo 
                                    formState={formState}
                                    locationChanged={locationChanged}
                                    nameChanged={nameChanged}
                                    emailChanged={emailChanged}
                                    phoneChanged={phoneChanged}
                                    dateChanged={dateChanged}
                                    mailingListChanged={mailingListChanged}
                                ></ContactInfo>
                            </>
                        }

                        {/* The hidden input fields are formsubmit.co's configuration. */}
                        <input type="text" name="_honey" style={{display: "none"}}></input>
                        {/* Autoresponse won't work with forms that are disabled reCAPTCHA and forms that are submitting through AJAX */}
                        <input type="hidden" name="_autoresponse" value={t("HOME.FORM-SUBMIT.SUCCESS-MESSAGE")}></input>
                        {/* <input type="hidden" name="_captcha" value="false"></input> */}
                        <input type="hidden" name="_cc" value="noscopex69@gmail.com,chrononautic@gmail.com"></input>

                        {
                            (formState.services.has('video') || formState.services.has('photography'))
                            &&
                            <MyTooltip formState={formState}>
                                <Button 
                                    id="order-submit" 
                                    type="submit" 
                                    variant={
                                        (
                                            (formState.name && formState.email && formState.phone && formState.date)
                                        )
                                        ? 'primary' : 'secondary'
                                    }
                                >
                                    {t("HOME.FORM-SUBMIT.SUBMIT")}
                                </Button>
                            </MyTooltip>
                        }
                        
                    </div>
                </form>
                

                <Modal
                    id="form-modal-opened"
                    show={this.state.modalOpen}
                    size="xl"
                    onHide={() => this.setState({
                        modalOpen: false
                    })}
                    dialogClassName="modal-150w"
                    aria-labelledby="form-submitted"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="form-submitted">
                            {
                                (this.state.loadingHeader)
                                &&
                                <div id="loading-header">
                                    {this.state.loadingHeader}
                                </div>
                            }

                            {
                                (this.state.successHeader)
                                &&
                                <div id="success-header">
                                    {this.state.successHeader}
                                </div>
                            }
                            
                            {
                                (this.state.failureHeader)
                                &&
                                <div id="failure-header">
                                    {this.state.failureHeader}
                                </div>
                            }   
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (this.state.loadingRing)
                            &&
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                        
                        {
                            (this.state.successMessage)
                            &&
                            <div id="success-message">
                                <span>{this.state.successMessage}</span>

                                <img 
                                    src="/media/images/icons/success_icon.svg"
                                    alt="Submission Success icon"
                                    className="submission-icon"
                                />
                            </div>
                        }

                        {
                            (this.state.failureMessage)
                            &&
                            <div id="failure-message">
                                <span>{this.state.failureMessage}</span>

                                <img 
                                    src="/media/images/icons/failure_icon.svg"
                                    alt="Submission Failure icon"
                                    className="submission-icon"
                                />
                            </div>
                        }

                    </Modal.Body>
                </Modal>
            </>

        )
    }
}

export default withTranslation()(OrderForm);