import { Component } from "react";

class TeamMember extends Component {

    render () {

        return (
            
            <div className="member-card">
                <img className="profile-pic" src={this.props.teamMember.avatarURL} alt={"A picture of " + this.props.teamMember.name} />
                <div className="data-section">
                    <h1 className="member-name">{this.props.teamMember.name}</h1>

                    <ul className="bio-line-list">
                        {
                            this.props.teamMember.bio.map((bioLine, index) => 
                                (<li key={index} className="bio-line">{bioLine}</li>)  
                            )
                        }   
                    </ul>
                </div>
            </div>
        )
    }
    
}

export default TeamMember;