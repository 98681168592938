import { Component } from "react";
import { withTranslation } from 'react-i18next';

class CustomOrder extends Component {
    
    render() {
        const {t, formState, descriptionChanged, exampleURLsChanged} = this.props

        return (
            <div id="custom-order">
                {/* A custom category to give the user a typical price for the sort of job they want */}
                {/* <label htmlFor="custom-category" className="form-label">Custom Catogory</label>
                <select name="custom-category" className="form-checkbox" required>
                    <option value="music video">Music Video</option>
                    <option value="editing">Editing</option>
                </select> */}


                <div className="form-section">
                    <div className="header-block">
                        <h2>{t("HOME.HEADERS.CUSTOM")}</h2>
                        <h5>{t("HOME.SUB-HEADERS.CUSTOM")}</h5>
                    </div>

                    <label className="letter-input-label" htmlFor="description">{t("HOME.LABELS.CUSTOM.DESCRIPTION")}</label>
                    <textarea 
                        defaultValue={formState.description} 
                        onChange={descriptionChanged} 
                        id="description" 
                        className="form-control" 
                        name="description" 
                        placeholder={t("HOME.VALUES.DESCRIPTION")}
                        required
                    >
                    </textarea>

                    <label className="letter-input-label" htmlFor="example-urls">{t("HOME.LABELS.CUSTOM.EXAMPLE")}</label>
                    <input 
                        type="text"
                        id="example-urls" 
                        className="form-control" 
                        name="example-urls" 
                        placeholder={"youtube.com/example, " + t("COMMON.ETC")}
                        onChange={exampleURLsChanged}
                        value={formState.exampleURLs} 
                    ></input>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CustomOrder);