import './App.scss';
import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
import OrderForm from './Components/Pages/Order/OrderForm';
import Samples from './Components/Pages/Samples/Samples';
import Team from './Components/Pages/Team/Team';

import "bootstrap/dist/css/bootstrap.min.css"
import { Route, Routes } from 'react-router-dom';
import { Component, Suspense } from 'react';

import Policy from './Components/Pages/Policy';
import Terms from './Components/Pages/Terms';
import Background from './Components/Layout/Background';
import PriceTab from './Components/Layout/PriceTab';

import { calculateCheckboxPrice, calculateRadioPrice } from './Helpers/PriceCalculators';
import Contact from './Components/Pages/Contact';
import VisibilityDetector from './Components/Util/VisibilityDetector';


class App extends Component  {

  state = {
    currentURL: "",

    // Basics
    orderType: null, // Wedding, Batpism, Wedding-with-baptism, Custom. (Future: Podcast, Product photography, etc)
    orderTypeVisibility: false,
    footerVisibility: false,
    services: new Set(), // Video, Photography
    
    // For order types BESIDES custom.
    video: { 
        package: new Set(),
        coverage: null,
    },
    photography: {
        eventSize: null,
        package: new Set(),
        album: null,
    },
    
    // Custom Orders
    description: null,
    exampleURLs: '', // Links to examples that will help us understand what the client wants.

    // Drone, Smoke machine, Studio, ((Backdrop ???)), etc
    extras: new Set(),
    location: null,
    
    name: '',
    email: '',
    phone: '',
    date: '',
    mailingList: false,

    // It will definately cost at least this much.
    minVideoPrice: null, 
    minPhotographyPrice: null, 
    minExtrasPrice: null, 
    // It might cost this much.
    maxVideoPrice: null,
    maxPhotographyPrice: null,
    maxExtrasPrice: null
  }

  static defaultProps = {

    prices: {
      // Prices for a wedding.
      weddingPrices: {
        video: {
            min: 400, // For a simple highlights video.
  
            package: {
              preparations: 100,
              party: 100,
              nextDay: 100
            },
  
            coverage: {
              highlights: 0, // +0€
              detailed: 200 // +200€
            }
        },
  
        photography: {
          min: 300,
  
          eventSize: {
            small: 0,
            medium: 100,
            large: 200
          },
  
          package: {
            preparations: {
              min: 100,
              max: 200
            },
            party: 200,
            nextDay: {
              min: 150,
              max: 300
            } 
          },
  
          album: {
            noAlbum: 0,
            sparseAlbum: 100,
            mediumAlbum: 150,
            denseAlbum: 200
          }
        },
  
        extras: {
          droneVideo: 200,
          dronePhotography: 200
        }
      },
  
      // Prices for a baptism.
      baptismPrices: {
        video: {
            min: 400, // For a simple highlights video.
  
            package: {
              family: 100, // +100€ 
              party: 100 // +100€ 
            },
            coverage: {
                detailed: 200, // +200€ 
                highlights: 0 // +0€ 
            },
        }, 
  
        photography: {
          min: 300,

          eventSize: {
            small: 0,
            medium: 100,
            large: 200
          },
  
          package: {
            family: 150,
            party: 200,
          },
  
          album: {
            noAlbum: 0,
            sparseAlbum: 100,
            mediumAlbum: 150,
            denseAlbum: 200
          }
        },
  
        extras: {
          droneVideo: 200,
          dronePhotography: 200
        }
      },

      // Prices for wedding with baptism.
      weddingWithBaptismPrices: {
        video: {
            min: 450, // For a simple highlights video.
  
            package: {
              preparations: 100,
              party: 100,
              nextDay: 150
            },
  
            coverage: {
              highlights: 0, // +0€
              detailed: 300 // +300€
            }
        },
  
        photography: {
          min: 500,

          eventSize: {
            small: 0,
            medium: 100,
            large: 200
          },
  
          package: {
            preparations: {
              min: 150,
              max: 250
            },
            party: 200,
            nextDay: {
              min: 300,
              max: 600
            } 
          },
  
          album: {
            noAlbum: 0,
            sparseAlbum: 100,
            mediumAlbum: 150,
            denseAlbum: 200
          }
        },
  
        extras: {
          droneVideo: 200,
          dronePhotography: 200
        }
      },
  
      // Prices for a custom order.
      customPrices: {
        
        video: {
            min: 300, // Only interested in jobs that cost over 300.
        },
  
        photography: {
            min: 100, 
        },
  
        extras: {
            drone: 200,
            smokeMachine: 40,
            studio: 50
        }
      }

    }
  }

  componentDidMount() {
    // An array with the URLs of the images that need to be prefetched.
    const photos = [
      "/media/images/buttons/video.jpg",
      "/media/images/buttons/photography.jpg",
      "/media/images/album/Sparse/2.jpg",
      "/media/images/album/Medium/1.jpg",
      "/media/images/album/Dense/1.jpg",
      "/media/images/album/overlay.png",
      "/media/images/icons/success_icon.svg",
      "/media/images/icons/failure_icon.svg"
    ];

    // Loop though each URL source.
    photos.forEach((photo) => {
        // Create a new <img /> element.
        const img = new Image();
        // Set its source to the photo URL.
        img.src = photo;
        // Store the images in the window object to avoid reloading after caching.
        window[photo] = img;
    });
  }

  componentDidUpdate(prevProps, prevState) {

    // Check if the user has changed order type.
    if(prevState.orderType !== this.state.orderType) {

      setTimeout(() => {
          // Scroll into view the next form-section.
          document.getElementById('services-section').scrollIntoView();
      }, 80);
      
    }

  }


  orderTypeChanged = (event) => {
    // Get the current value of the order type.
    const selectedOrderTypeElement = event.target;

    // Clear the form.
    document.getElementsByTagName('form')[0].reset();

    // Re-check the current value of the order type after the form is cleared.
    selectedOrderTypeElement.checked = true;

    this.setState({
      orderType: selectedOrderTypeElement.value,
      services: new Set([]), // Clear the services.

      // Video options for non-custom orders
      video: {
        package: new Set([]), // Clear the package.
        coverage: '',
      },
      // Photography options for non-custom orders
      photography: {
        eventSize: '',
        package: new Set([]), // Clear the package.
        album: '',
      },
      // Custom order options
      description: null,
      exampleURLs: '',

      extras: new Set([]), // Clear the extras.
      location: null,

      minVideoPrice: 0,
      maxVideoPrice: 0,
      minPhotographyPrice: 0,
      maxPhotographyPrice: 0,
      minExtrasPrice: 0,
      maxExtrasPrice: 0
    });
  }


  servicesChanged = (event) => {
    const selectedServiceOption = event.target.value;

    // Check if the selected item was found in the state. 
    if(this.state.services.has(selectedServiceOption)) {
      // Remove the selected item from the state.
      this.setState((state) => ({
          // Return all the services aside from the option that was unchecked.
          services: new Set([...state.services].filter((serviceOption) => serviceOption !== selectedServiceOption)),
        })
      );
      // In case video was unchecked, clear all of the state options that belong to 
      // video service option.
      if(selectedServiceOption === 'video') {
        this.setState((state) => ({
          video: {
            package: new Set([]),
            coverage: '',
          },
          extras: new Set([...state.extras].filter((extrasOption) => extrasOption !== 'droneVideo')),
          minVideoPrice: 0,
          maxVideoPrice: 0,
          minExtrasPrice: ((extras, minExtrasPrice, prices) => {
            // Check if the order type is not custom.
            if(state.orderType !== 'custom') {
              // Check if the user had selected the droneVideo option.
              if(extras.has('droneVideo')){
                return (minExtrasPrice - prices.weddingPrices.extras.droneVideo);
              }
            }
            return minExtrasPrice
          })(state.extras, state.minExtrasPrice, this.props.prices)
        }))
      }
      // In case photography was unchecked, clear all of the state options that belong to
      // photography service option.
      if(selectedServiceOption === 'photography') {
        this.setState((state) => ({
          photography: {
            eventSize: '',
            package: new Set([]),
            album: '',
          },
          extras: new Set([...state.extras].filter((extrasOption) => extrasOption !== 'dronePhotography')),
          minPhotographyPrice: 0,
          maxPhotographyPrice: 0,
          minExtrasPrice: ((extras, minExtrasPrice, prices) => {
            // Check if the order type is not custom.
            if(state.orderType !== 'custom') {
              // Check if the user had selected the dronePhotography option.
              if(extras.has('dronePhotography')){
                return (minExtrasPrice - prices.weddingPrices.extras.dronePhotography);
              }
            }
            return minExtrasPrice
          })(state.extras, state.minExtrasPrice, this.props.prices)
        }))
      }
    } else {
      // Add the selected item to the state.
      this.setState((state) => ({
          services: new Set(state.services.add(selectedServiceOption)),
      }));

      // Initialize the min price.
      let minPrice = 0;
      
      // Check if video service option was selected.
      if(selectedServiceOption === 'video') {
        // Check if the order is of type 'wedding'.
        if(this.state.orderType === 'wedding') {
          minPrice += this.props.prices.weddingPrices.video.min;
        }
        // Check if the order is of type 'baptism'.
        if(this.state.orderType === 'baptism') {
          minPrice += this.props.prices.baptismPrices.video.min;
        }
        // Check if the order is of type 'weddingWithBaptism'.
        if(this.state.orderType === 'weddingWithBaptism') {
          minPrice += this.props.prices.weddingWithBaptismPrices.video.min;
        }
        // Check if the order is of type 'custom'.
        if(this.state.orderType === 'custom') {
          minPrice += this.props.prices.customPrices.video.min;
        }

        this.setState({
          minVideoPrice: minPrice
        })
      }
      // Check if photography service option was selected.
      if(selectedServiceOption === 'photography') {
        // Check if the selected order type is wedding.
        if(this.state.orderType === 'wedding') {
          minPrice += this.props.prices.weddingPrices.photography.min;
        }
        // Check if the selected order type is baptism.
        if(this.state.orderType === 'baptism') {
          minPrice += this.props.prices.baptismPrices.photography.min;
        }
        // Check if the order is of type 'weddingWithBaptism'.
        if(this.state.orderType === 'weddingWithBaptism') {
          minPrice += this.props.prices.weddingWithBaptismPrices.photography.min;
        }
        // Check if the selected order type is custom.
        if(this.state.orderType === 'custom') {
          minPrice += this.props.prices.customPrices.photography.min;
        }

        this.setState({
          minPhotographyPrice: minPrice
        })
      }
    
    }
  }

  videoPackageChanged = (event) => {
    const selectedPackageOption = event.target.value;

    // Initialize the price by which the minVideoPrice will be reduced or increased by.
    let priceChange = calculateCheckboxPrice(
      selectedPackageOption, 
      this.state.orderType, 
      this.props.prices, 
      'video', 
      'package'
    );

    // Check if the selected item was found in the state. 
    if(this.state.video.package.has(selectedPackageOption)) {
      // Remove the selected item from the state.
      this.setState((state) => ({
          video: {
              package: new Set([...state.video.package].filter((packageOption) => packageOption !== selectedPackageOption)),
              coverage: state.video.coverage
          },
          // Reduce the minVideoPrice by the value of the box that was unchecked.
          minVideoPrice: state.minVideoPrice - priceChange.minPriceChange
      }));
    } else {
      // Add the selected item to the state.
      this.setState((state) => ({
          video: {
              package: new Set(state.video.package.add(selectedPackageOption)),
              coverage: state.video.coverage
          },
          // Increase the minVideoPrice by the value of the box that was checked.
          minVideoPrice: state.minVideoPrice + priceChange.minPriceChange
      }));

    }
  }

  videoCoverageChanged = (event) => {
    const selectedCoverageOption = event.target.value;

    // Get the old and new prices for the option group that changed.
    let prices = calculateRadioPrice(
      this.state.video.coverage, 
      selectedCoverageOption, 
      this.state.orderType, 
      this.props.prices, 
      'video', 
      'coverage'
    );

    this.setState(state => ({
        video: {
          package: state.video.package,
          coverage: selectedCoverageOption
        },
        minVideoPrice: (state.minVideoPrice - prices.oldPrice) + prices.newPrice
    }))
  }

  photographyEventSizeChanged = (event) => {

    const selectedEventSizeOption = event.target.value;

    // Get the old and new prices for the option group that changed.
    let prices = calculateRadioPrice(
      this.state.photography.eventSize, 
      selectedEventSizeOption, 
      this.state.orderType, 
      this.props.prices, 
      'photography', 
      'eventSize'
    );

    this.setState(state => ({
        photography: {
          eventSize: selectedEventSizeOption,
          package: state.photography.package,
          album: state.photography.album
        },
        minPhotographyPrice: (state.minPhotographyPrice - prices.oldPrice) + prices.newPrice
    }))
  }

  photographyPackageChanged = (event) => {
    const selectedPackageOption = event.target.value;

    // Initialize the price by which the minVideoPrice will be reduced or increased by.
    let priceChange = calculateCheckboxPrice(
      selectedPackageOption, 
      this.state.orderType, 
      this.props.prices, 
      'photography', 
      'package'
    );

    // Check if the selected item was found in the state. 
    if(this.state.photography.package.has(selectedPackageOption)) {
      // Remove the selected item from the state.
      this.setState((state) => ({
          photography: {
              eventSize: state.photography.eventSize,
              package: new Set([...state.photography.package].filter((packageOption) => packageOption !== selectedPackageOption)),
              album: state.photography.album
          },
          // Reduce the minPhotographyPrice by the value of the box that was unchecked.
          minPhotographyPrice: state.minPhotographyPrice - priceChange.minPriceChange,
          // Reduce the maxPhotographyPrice by the value of the box that was unchecked.
          maxPhotographyPrice: state.maxPhotographyPrice - priceChange.maxPriceChange
      }))
    } else {
      // Add the selected item to the state.
      this.setState((state) => ({
          photography: {
              eventSize: state.photography.eventSize,
              package: new Set(state.photography.package.add(selectedPackageOption)),
              album: state.photography.album
          },
          // Increase the minPhotographyPrice by the value of the box that was unchecked.
          minPhotographyPrice: state.minPhotographyPrice + priceChange.minPriceChange,
          // Increase the maxPhotographyPrice by the value of the box that was unchecked.
          maxPhotographyPrice: state.maxPhotographyPrice + priceChange.maxPriceChange
      }))
    }
  }

  photographyAlbumChanged = (event) => {
    
    const selectedAlbumOption = event.target.value;

    // Get the old and new prices for the option group that changed.
    let prices = calculateRadioPrice(
      this.state.photography.album, 
      selectedAlbumOption, 
      this.state.orderType, 
      this.props.prices, 
      'photography', 
      'album'
    );
    
    this.setState(state => ({
        photography: {
          eventSize: state.photography.eventSize,
          package: state.photography.package,
          album: selectedAlbumOption
        },
        minPhotographyPrice: (state.minPhotographyPrice - prices.oldPrice) + prices.newPrice
    }));
  }

  descriptionChanged = (event) => {
    this.setState({
        description: event.target.value
    })
  }
  exampleURLsChanged = (event) => {
    this.setState({
        exampleURLs: event.target.value
    })
  }

  extrasChanged = (event) => {
    const selectedExtrasOption = event.target.value;

    // Initialize the price by which the minVideoPrice will be reduced or increased by.
    let priceChange = calculateCheckboxPrice(
      selectedExtrasOption, 
      this.state.orderType, 
      this.props.prices, 
      'extras'
    );

    // Check if the selected item was found in the state. 
    if(this.state.extras.has(selectedExtrasOption)) {
        // Remove the selected item from the state.
        this.setState((state) => ({
            extras: new Set([...state.extras].filter((extrasOption) => extrasOption !== selectedExtrasOption)),
            // Reduce the minExtrasPrice by the value of the box that was unchecked.
            minExtrasPrice: state.minExtrasPrice - priceChange.minPriceChange
        }))
    } else {
        // Add the selected item to the state.
        this.setState((state) => ({
            extras: new Set(state.extras.add(selectedExtrasOption)),
            // Increase the minExtrasPrice by the value of the box that was unchecked.
            minExtrasPrice: state.minExtrasPrice + priceChange.minPriceChange
        }))
    }
  }

  locationChanged = (event) => {
    this.setState({
      location: event.target.value
    })
  }

  nameChanged = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  emailChanged = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  phoneChanged = (event) => {
    this.setState({
      phone: event.target.value
    })
  }

  dateChanged = (event) => {
    this.setState({
      date: event.target.value
    })
  }

  mailingListChanged = (event) => {
    // Get the current status of the mailing list checkbox.
    let isChecked = this.state.mailingList;

    // Toggle the status of the mailing list checkbox.
    if(isChecked) {
      // Given that it's checked, I need to uncheck it.
      isChecked = false;
    } else {
      // Given that it's not checked, I need to check it.
      isChecked = true
    }
    
    this.setState({
      mailingList: isChecked
    })
  }

  setCurrentURL = (currentURL) => {
    // Set the current URL that we've gotten from the useLocation hook in the Header component.
    this.setState({
      currentURL: currentURL
    });
  }

  // Check if the orderType section is not visible to show the animated arrows.
  orderTypeVisibilityChanged = (visibilityStatus) => {
    this.setState({
      orderTypeVisibility: visibilityStatus
    })
  }

  // Check when the footer is visible so that the arrows do not show up when the
  // user has scrolled beyond the orderType content.
  footerVisibilityChanged = (visibilityStatus) => {
    this.setState({
      footerVisibility: visibilityStatus
    });
  };

  render() {
    return (
      <Suspense fallback="loading">

        <div className="App">
          <Header setCurrentURL={this.setCurrentURL}></Header>
          
          <Routes>
            {/* Navbar Routes */}
            <Route 
              exact 
              path="/"
              element={
                <OrderForm 
                  formState={this.state}
                  prices={this.props.prices}
                  orderTypeChanged={this.orderTypeChanged}
                  servicesChanged={this.servicesChanged}
                  videoPackageChanged={this.videoPackageChanged}
                  videoCoverageChanged={this.videoCoverageChanged}
                  photographyEventSizeChanged={this.photographyEventSizeChanged}
                  photographyPackageChanged={this.photographyPackageChanged}
                  photographyAlbumChanged={this.photographyAlbumChanged}
                  descriptionChanged={this.descriptionChanged}
                  exampleURLsChanged={this.exampleURLsChanged}
                  extrasChanged={this.extrasChanged}
                  locationChanged={this.locationChanged}
                  nameChanged={this.nameChanged}
                  emailChanged={this.emailChanged}
                  phoneChanged={this.phoneChanged}
                  dateChanged={this.dateChanged}
                  mailingListChanged={this.mailingListChanged}
                  orderTypeVisibilityChanged={this.orderTypeVisibilityChanged}
                />
              }
            ></Route>
            <Route path="/samples" element={<Samples />}></Route>
            <Route path="/team" element={<Team />}></Route>

            {/* Footer Routes */}
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/privacy-policy" element={<Policy />}></Route>
            <Route path="/terms-of-use" element={<Terms />}></Route>
          </Routes>

          <PriceTab 
            formState={this.state}
          ></PriceTab>

          <VisibilityDetector onVisibilityChange={this.footerVisibilityChanged}>
              <Footer></Footer>
          </VisibilityDetector>
          {/* <Background></Background> */}

        </div>

      </Suspense>
    );
  }
}

export default App;
