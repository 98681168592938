import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from 'react-router-dom';
import CookieConsentBanner from './Components/Layout/CookieConsentBanner';
import { initializeAnalytics, trackPageView } from './analytics';
import { HelmetProvider } from 'react-helmet-async';

// import i18n (needs to be bundled ;)) 
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <HelmetProvider>
        <App />
        <CookieConsentBanner
          onConsent={() => {
            initializeAnalytics();
            trackPageView();
          }}
          onReject={() => {
            // console.log('GA4 tracking disabled');
          }}
        />
      </HelmetProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for examples: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
