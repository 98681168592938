import { Component } from "react";
import TeamMember from "./TeamMember";
import './Team.scss';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class Team extends Component {

    render () {
        const {t} = this.props;
        const teamMembers = [
            {
                "name": t("TEAM.NAMES.CHRISTOS"),
                "bio": [
                    t("TEAM.BIOS.CHRISTOS.LINE-1"),
                    t("TEAM.BIOS.CHRISTOS.LINE-2"),
                    t("TEAM.BIOS.CHRISTOS.LINE-3"),
                    t("TEAM.BIOS.CHRISTOS.LINE-4")
                ],
                "avatarURL": "/media/images/members/Christos.jpg"
            },
            {
                "name": t("TEAM.NAMES.NICOL"),
                "bio": [
                    t("TEAM.BIOS.NICOL.LINE-1"),
                    t("TEAM.BIOS.NICOL.LINE-2"),
                    t("TEAM.BIOS.NICOL.LINE-3")
                ],
                "avatarURL": "/media/images/members/Nicol.jpg"
            },
            {
                "name": t("TEAM.NAMES.GIOTAKOS"),
                "bio": [
                    t("TEAM.BIOS.GIOTAKOS.LINE-1"),
                    t("TEAM.BIOS.GIOTAKOS.LINE-2"),
                    t("TEAM.BIOS.GIOTAKOS.LINE-3")
                ],
                "avatarURL": "/media/images/members/Giotakos.jpg"
            },
            {
                "name": t("TEAM.NAMES.CHARALAMPOS"),
                "bio": [
                    t("TEAM.BIOS.CHARALAMPOS.LINE-1"),
                    t("TEAM.BIOS.CHARALAMPOS.LINE-2"),
                    t("TEAM.BIOS.CHARALAMPOS.LINE-3")
                ],
                "avatarURL": "/media/images/members/Charalampos.jpg"
            },
            {
                "name": t("TEAM.NAMES.PARTNERS"),
                "bio": [
                    t("TEAM.BIOS.PARTNERS.LINE-1")
                ],
                "avatarURL": "/media/images/members/Partners.png"
            },
        ]

        return (
            <div className="main-container team-container">
                <div className="header-block">
                    <h2>{t("TEAM.HEADERS.OUR-TEAM")}</h2>
                    <h5>{t("TEAM.SUB-HEADERS.OUR-TEAM")}</h5>
                </div>
                <div className="team">
                    {
                        teamMembers.map((teamMember, index) => 
                          (<TeamMember key={index} teamMember={teamMember}></TeamMember>)  
                        )
                    }
                    
                </div>
                <Link to="/samples" id="to-work-samples-button">{t("TEAM.SAMPLES-BUTTON")}</Link>

            </div>
        )
    }
}

export default withTranslation()(Team);