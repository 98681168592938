
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Nav, Navbar, Container, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";


import './Header.scss';
import { useEffect, useState } from 'react';


const Header = ({setCurrentURL}) => {

  const { t } = useTranslation();
  // Get the current URL / page pathname from the location object.
  const location = useLocation();
  
  // Detect when there's a change in the location pathname.
  useEffect(() => {
    // Set the current URL to the app's state.
    setCurrentURL(location.pathname);

    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [location, setCurrentURL]);

  // Add a new state variable to control the visibility of the off-canvas menu.
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  // Function to handle the closing of the off-canvas menu.
  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  return (
    <Navbar key="md" variant="dark" expand="md">
      <Container fluid>

        <Link className="navbar-brand" to="/">
            <span>
              Video
            </span>
            <img
              src="media/images/icons/lens_icon.svg"
              className="logo d-inline-block align-top"
              alt="Lens logo"
            />
            <span>
              Photography
            </span>
        </Link>

        <Navbar.Toggle 
          aria-controls={`offcanvasNavbar-expand-sm`} 
          onClick={() => setShowOffcanvas(true)}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-sm`}
          aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
          placement="end"
          show={showOffcanvas} // Add the show prop.
          onHide={handleCloseOffcanvas} // Add the onHide prop.
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          
          <Offcanvas.Body>
            
            <Nav className="justify-content-end flex-grow-1">
              <Nav.Link id="home" as={NavLink} exact="true" to="/" onClick={handleCloseOffcanvas}>
                {t("TABS.HOME")}
              </Nav.Link>
              <Nav.Link as={NavLink} exact="true" to="/team" onClick={handleCloseOffcanvas}>
                {t("TABS.TEAM")}
              </Nav.Link>
              <Nav.Link as={NavLink} exact="true" to="/samples" onClick={handleCloseOffcanvas}>
                {t("TABS.SAMPLES")}
              </Nav.Link>

              {/* I will need this when I have users.
              <NavDropdown
                title="Username"
                id="offcanvasNavbarDropdown-expand-sm"
              >
                <NavDropdown.Item href="#action3">
                  My Orders
                </NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Edit Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown> 
              */}

              <LanguageSelector />
            </Nav>

            
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header;