import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./Translations/en.json"
import el from "./Translations/el.json"

const resources = {
  en: {
    translation: en
  },
  el: {
    translation: el
  }
};

i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources,
    fallbackLng: 'el',
    lng: 'el', // default language
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
});


export default i18n;
