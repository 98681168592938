import { useTranslation } from 'react-i18next';

function DirectionsNotice () {
    const { t } = useTranslation();

    return (
        <span className="directions"><strong>{t("HOME.LABELS.ORDER-TYPE.NOTICE")}</strong></span>
    )
}

export default DirectionsNotice;