import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function Sample(props) {
    const [show, setShow] = useState(false);

    return (
        <>
            {
                (props.item.type === 'video')
                &&
                <video className="media-button vid" controls>
                    <source 
                        src={props.item.source+"#t=0.8"} 
                        type="video/mp4"
                        controls="controls" 
                        preload="metadata"
                    >
                    </source>
                </video>
            }
            {
                (props.item.type === 'photo')
                &&   
                <img 
                    onClick={() => setShow(true)} 
                    src={props.item.source} 
                    className="media-button img-responsive" 
                    alt="gallery pic"
                />
            }

            <Modal
                show={show}
                size="xl"
                onHide={() => setShow(false)}
                dialogClassName="modal-150w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.item.modalHeader}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={props.item.source} className="modal-content" alt="gallery pic"/>
                </Modal.Body>
            </Modal>
        </>
    );

}

export default Sample;
