import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

function MyTooltip({children, formState}) {

  const { t } = useTranslation();

  const renderTooltip = (props) => (

    <Tooltip id="button-tooltip" {...props}>

      {
        !(formState.name && formState.email && formState.phone && formState.date)
        &&
        <span>{t("HOME.FORM-SUBMIT.TOOLTIP.FILL-OUT")}</span>
      }

      {
        (formState.name && formState.email && formState.phone && formState.date)
        &&
        <span>{t("HOME.FORM-SUBMIT.TOOLTIP.FILLED-OUT")}</span>
      }
    </Tooltip>

  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
}

export default MyTooltip;